import { CurrencyCode, PlanType } from '@buggy/shared'
import { isEqual, isUndefined, omit, omitBy, set } from 'lodash-es'
import isEmpty from 'lodash-es/isEmpty'
import { z } from 'zod'
import {
  BasicRequest,
  CampaignInvitationResponse,
  PaginatedBasicRequest,
  StringifyRequest,
  UserAPITagType,
  WorkspacesAPITagType,
  api,
  infiniteConfigSetting,
} from '@/api/api'
import {
  GoogleAnalyticsAdminAPITagType,
  googleAnalyticsAdminApi,
} from '@/api/google-analytics-admin-api'
import { GoogleAuthorizationStatus } from '@/api/oauth-api'
import { TappayStatusCode } from '@/api/tappay/tappay-api'
import { WorkspaceType } from '@/api/user-api'
import getWorkspaceApiUrl from '@/api/utils/get-workspace-api-url'
import { Duration } from '@/components/common/duration-select'
import { AllCountryCode } from '@/constants/country-code'
import { PostType } from '@/constants/post-type'
import { updateSearchId } from '@/hooks/use-search-id'
import {
  Campaign,
  CampaignPicture,
  CooperationItemBrand,
  fetchCampaignSuccess,
  getCampaignKolsSuccess,
} from '@/store/campaign'
import { RootState } from '@/store/store'
import { SubscribeCrawlerStatusRequest } from '@/types/api/kols'
import {
  BindCreditCardRequest,
  PaymentProvider,
  VerifyCreditCardRequest,
  VerifySubscriptionPaymentTransactionRequest,
} from '@/types/api/payment'
import { FetchPostRequest } from '@/types/api/post'
import {
  AddCollectionPostRequest,
  BasicPostCollectionPostRequest,
  BasicPostCollectionRequest,
  DeleteCollectionPostRequest,
  DuplicatePostCollectionRequest,
  FetchPostCollectionListRequest,
  FetchPostCollectionPostListRequest,
  ModifyPostCollectionRequest,
} from '@/types/api/post-collection'
import { RedeemTrialCodeRequest } from '@/types/api/trial-code'
import {
  AuthAssignmentSliceRequestObject,
  BrandRecognitionSnap,
  CampaignKol,
  CampaignKolStatus,
  EditedBrandRecognitionSnap,
  ModelAuthAssignment,
  ModelFileItem,
  ModelGuestUser,
  ModelInvoice,
  ModelWorkspaceAuth,
  WorkspaceRequestObject,
} from '@/types/mock-api-types'
import { Nullable } from '@/types/nullable'
import { ListResource } from '@/types/resource-type'
import { KolPostListSorter } from '@/types/schema/api/kol'
import { FetchPostResponse, postResponseSchema } from '@/types/schema/api/post'
import {
  FetchPostCollectionResponse,
  postCollectionResponseSchema,
} from '@/types/schema/api/post-collection'
import {
  PostCollectionList,
  PostCollectionOwnerList,
  postCollectionListSchema,
  postCollectionOwnerListSchema,
} from '@/types/schema/api/post-collection-list'
import {
  FetchPostCollectionPostListResponse,
  fetchPostCollectionPostListResponseSchema,
} from '@/types/schema/api/post-collection-post-list'
import {
  TutorialListResponse,
  tutorialListSchema,
} from '@/types/schema/api/search'
import { filterResourceType } from '@/types/schema/directory/kol-management'
import {
  KolSchemaModel,
  KolSchemaRemote,
  applyKolSchemaModel,
} from '@/types/schema/kol-schema'
import {
  BindCreditCardResponse,
  ExecuteSubscriptionPaymentTransactionResponse,
  VerifyCreditCardResponse,
  VerifySubscriptionPaymentTransactionResponse,
  bindCreditCardResponseSchema,
  executeSubscriptionPaymentTransactionResponseSchema,
  verifyCreditCardResponseSchema,
  verifySubscriptionPaymentTransactionResponseSchema,
} from '@/types/schema/payment'
import { PaymentSchema } from '@/types/schema/payment-schema'
import { postTypeSchema } from '@/types/schema/post-type'
import { PlatformType } from '@/types/schema/report-schema'
import { PlatformShortcode } from '@/utils/convert-platform'
import { Potential } from '@/utils/detail/potential'
import { BankId, CreditCardType } from '@/utils/get-credit-card'
import { DurationType } from '@/utils/get-duration'
import { camelizeKeys, decamelizeKeys } from '@/utils/humps'
import logger from '@/utils/logger'
import removeNil from '@/utils/remove-nil'
import updatePostCollectionPostQueryData from '@/utils/update-post-collection-post-query-data'
import { UTMInfo } from '@/utils/utm'

interface SubscriptionCaseRequest extends BasicRequest {
  subscriptionCaseID: number
}

interface SubscriptionCaseItemRequest extends SubscriptionCaseRequest {
  subscriptionCaseItemIds: number[]
}

type PaymentSettingRequest = {
  paymentSettingID: number
} & SubscriptionCaseRequest &
  PaymentSchema

interface UserRole {
  id: number
  name: string
  /***
   * @description
   * old plan
   *  - workspaceType_locale  e.g. 'vip_tw','payment_jp'
   *
   * new plan
   *  - only workspaceType e.g. 'premium','advanced','corporate','trial','enterprise-trial'
   */
  code: string
  /***
   * @description workspaceType, AllCountryCode mapping from code
   */
  workspaceType: WorkspaceType
  countryCode?: AllCountryCode
}

export interface PaymentMeta {
  type: CreditCardType
  lastFour: string
  expiryDate: string
  countryCode: string
  tokenStatus: string
  issuer: string
  bankId: BankId
}

export type Plan = {
  id: number
  userRole: UserRole
  duration: DurationType
  displayCurrency: string
  billingTwdPrice: number
}

export enum ProductCategory {
  Plan = 'plan', // 方案
  Report = 'report', // 洞察報表
  ChangePlanDiscount = 'change_plan_discount', // 方案變更折抵金金額
  RemainReportDiscount = 'remain_report_discount', // 洞察報表折抵金額
  Quota = 'quota', // 網紅解鎖額度
}

interface SubscriptionCaseItem {
  id: number
  category: ProductCategory
  amount: string
  quantity: string
  productAmount: string
  name: string
  description: string
  currency: CurrencyCode
  /**
   * @description When not null, it means that the subscription item is canceled
   */
  deletedAt: Nullable<string>
}

export type SubscriptionCaseModel = {
  id: number
  currency: CurrencyCode
  actualPayPrice: string
  name: string
  plan: Plan
  paymentMeta: PaymentMeta
  isCompany: boolean
  /**@description
   * - isActive: true, isDisable: false, 現在方案
   * - isActive: true, isDisable: true, 取消訂閱、前方案
   * - isActive: false 付款失敗
   * */
  isActive: boolean
  isDisable: boolean
  /**@description
   * 範例 :
   * 假如 用戶 7 / 1 購買, 服務實際上是 7 / 1 ~ 8 / 2 ， 多送購買當天的 1 天服務，所以 renewalDate 是 8 / 1， serviceEndTime 是 8 / 2
   * - serviceEndTime 服務到期日
   * */
  serviceEndTime: string
  /**@description
   * - renewalDate 扣款日, null 情況有: 扣款失敗、取消訂閱、前方案
   * */
  renewalDate: Nullable<string>
  paymentSetting: PaymentSchema & { id: number }
  subscriptionCaseItems: SubscriptionCaseItem[]
}

export type SubscriptionCaseResponse = {
  data: SubscriptionCaseModel[]
}

interface GoogleAnalyticsSetting {
  propertyId: string
  userId: string
  accessToken: string
  tokenStatus: GoogleAuthorizationStatus
}

type SetGoogleAnalyticsSettingRequest = Pick<
  GoogleAnalyticsSetting,
  'propertyId' | 'userId'
> &
  BasicRequest

interface FetchKOLResourceRequest extends BasicRequest {
  kolUUID: string
}

export enum SentimentEnum {
  Positive = 'positive',
  Negative = 'negative',
  Neutral = 'neutral',
  Unknown = 'unknown',
}

export interface Sentiment {
  [SentimentEnum.Negative]: number
  [SentimentEnum.Neutral]: number
  [SentimentEnum.Positive]: number
  [SentimentEnum.Unknown]: number
}

export interface PlatformSentiment {
  platform: PlatformShortcode
  sentiment: Sentiment
}

export interface KolSentiment {
  kolUUID: string
  kolName: string
  sentiment: Sentiment
}

export interface Comment {
  commentID: string
  commentType: PlatformShortcode
  postID: string
  pinned: boolean
  comment: string
  sentiment: SentimentEnum
  userName: string
}

export interface KOLAnalyze {
  url: string
  likeCount: number
  interactiveCount: number
  interactiveRate: number
  commentCount: number
  shareCount: number
  titleContent: string
  viewCount: number
  viewRate: number
  isVideo: boolean
  isLive: boolean
  isShort: boolean
  publishedAt: string
  isAdvertorial: boolean
  thumbnail: string
  crawledId: string
  postId: string
  igPostId: string
}

export enum CampaignKolAction {
  AcceptApply = 'accept',
  RejectApply = 'reject',
  Invite = 'invite',
  CancelInvitation = 'cancel_invitation',
  ForceRunning = 'force_running',
  CancelCooperation = 'cancel_cooperation',
}

interface ExecuteCampaignKolActionRequest extends BasicRequest {
  campaignID: number
  campaignKolIds: number[]
  action: CampaignKolAction
  invitationContent?: string
}

interface CampaignRequest extends BasicRequest {
  campaignID: number
  per_page?: number
  next_page?: string
}

interface CampaignKolRequest extends BasicRequest {
  campaignID: number
  kolID: string
}

interface KolKPI {
  platform: PlatformShortcode
  follower: number
}

interface KolAudience {
  malePercentage: number
  femalePercentage: number
  minAge: Nullable<number>
  maxAge: Nullable<number>
}

export enum RecommendKOLQuotationType {
  Post = 'post',
  Video = 'video',
}

export interface RecommendKOL {
  uuid: string
  names: string[]
  properties: string[]
  audience: Nullable<KolAudience>
  quotations: {
    type: RecommendKOLQuotationType
    minPrice: number
    maxPrice: Nullable<number>
  }[]
  verified: boolean
  visibility: boolean
  isRadarStar: boolean
  potential?: Potential
  kpi: KolKPI[]
  links: {
    platformType: PlatformShortcode
    url: string
  }[]
  campaignKolId: number
  campaignKolStatus: CampaignKolStatus
}

interface RecommendKOLResponse {
  total: number
  nextPage?: string
  data: RecommendKOL[]
}

type UpdateBrandRecognitionsRequest = BasicRequest & EditedBrandRecognitionSnap

interface CooperationItemBrandRequest
  extends Omit<CooperationItemBrand, 'pictures'> {
  pictures: Omit<CampaignPicture, 'file' | 'description'>[]
}

interface CooperationItemProductRequest extends CooperationItemBrandRequest {
  value: number
}

interface CreateCampaignRequest
  extends BasicRequest,
    Pick<
      Campaign,
      | 'name'
      | 'description'
      | 'startAt'
      | 'endAt'
      | 'platformInvolved'
      | 'campaignPurpose'
      | 'postTypes'
      | 'generalTagCodes'
      | 'applyEndTime'
      | 'isPublic'
      | 'cooperation'
      | 'qualifiedSamplePicture'
      | 'unqualifiedSamplePicture'
    > {
  brandRecognitionSnap: EditedBrandRecognitionSnap
  brands: CooperationItemBrandRequest[]
  products: CooperationItemProductRequest[]
}

export interface FetchCampaignListRequest
  extends BasicRequest,
    StringifyRequest {
  page: number
  isOver: boolean
  campaignName?: string
  remark?: string
  sortBy?: string[]
}

interface FetchCampaignRequest extends BasicRequest {
  campaignID: number
  /**
   * For api cache
   */
  selectedKolListTab?: string
}

interface UpdateCampaignRequest extends BasicRequest {
  campaign: Omit<CreateCampaignRequest, 'workspaceId'>
  campaignID: number
}

interface UpdateCampaignRemarkRequest
  extends BasicRequest,
    Pick<Campaign, 'remark'> {
  campaignID: number
}

interface UpdateCampaignBrandRecognitionRequest extends BasicRequest {
  campaignId: number
  brandRecognitionSnap: Omit<BrandRecognitionSnap, 'iconFile'>
}

interface DeleteCampaignRequest extends BasicRequest {
  campaignID: number
}

export enum CampaignKolListStatus {
  Preparing = 'preparing',
  Inviting = 'inviting',
  Applied = 'applied',
  Running = 'running',
  Done = 'done',
  Close = 'close',
}

enum CampaignKolListSorting {
  RemarkDesc = 'remark desc',
  RemarkAsc = 'remark asc',
  RewardDesc = 'reward desc',
  RewardAsc = 'reward asc',
  UpdatedAtDesc = 'updated_at desc',
  UpdatedAtAsc = 'updated_at asc',
}

export interface FetchCampaignKolListRequest
  extends BasicRequest,
    StringifyRequest {
  campaignId: number
  page: number
  campaignKolStatus: CampaignKolListStatus
  name?: string
  sortBy?: CampaignKolListSorting[]
}

interface RemoveCampaignKolsRequest extends BasicRequest {
  campaignId: number
  campaignKolIds: number[]
}

interface UpdateCampaignKolsRequest extends BasicRequest {
  campaignKolIds: number[]
  campaignKol: Partial<Pick<CampaignKol, 'remark' | 'attachments'>>
  campaignId: number
}

interface AddCampaignKolsRequest extends BasicRequest {
  kolUUIDs: string[]
  campaignId: number
}

interface BatchUpdateKolsResponse {
  failed: number
  succeeded: number
}

interface AppropriationsRequest extends BasicRequest {
  campaignId: number
  campaignKolIds: number[]
}

export enum IssueType {
  KolNotPost = 'kol_not_post',
  KolNotContact = 'kol_not_contact',
  Other = 'other',
}

interface ComplaintsRequest extends BasicRequest {
  campaignId: number
  campaignKolIds: number[]
  complaint: {
    issueType: IssueType
    issueMessage?: string
  }
}

interface UpdateUtmRequest extends BasicRequest {
  campaignId: number
  campaignKolIds: number[]
  utmInfo: UTMInfo
}

export interface PaymentTransaction {
  campaignName: string
  paymentMethod: string
  paymentDate: string
  paymentAmount: number
  kol: {
    id: number
    uuid: string
    name: string[]
    isVerified: Nullable<boolean>
    isRadarStar: Nullable<boolean>
    thumbnailUrl: string
  }[]
  campaignId: number
}

interface FetchPaymentTransactionListRequest extends BasicRequest {
  page: number
  perPage: number
}

interface FetchCampaignPaymentTransactionResponse {
  orderPrice: {
    subtotal: string
    discount: string
    fee: string
    total: string
    currencyCode: string
    commissionPercentage: string
  }
  campaignKolInfos: CampaignKol[]
}

export interface FetchCampaignPaymentTransactionRequest extends BasicRequest {
  campaignKolIds: number[]
  campaignId: number
}

interface FetchCampaignPaymentSettingRequest extends BasicRequest {
  campaignId: number
}

interface FetchCampaignPaymentSettingResponse {
  invoiceInfo?: Omit<PaymentSchema, 'planID' | 'prime'>
}

interface TappayPaymentTransactionResponse {
  status: TappayStatusCode
  redirectUrl?: string
}

export type TappayPaymentTransactionParameter =
  | { useRemembered: false; tappayPrime: Nullable<string> }
  | { useRemembered: true }

export interface ExecuteCampaignPaymentTransactionRequest extends BasicRequest {
  campaignId: number
  campaignKolIds: number[]
  contactParameter: Omit<PaymentSchema, 'planID' | 'prime' | 'couponCode'>
  paymentTransactionParameter: TappayPaymentTransactionParameter
}

interface TappayPaymentTransactionVerifyRequest extends BasicRequest {
  recTradeId: string
  authCode: Nullable<string>
  bankTransactionId: string
}

interface CampaignPaymentVerifyResponse {
  isSucceeded: boolean
  campaignId: number
}

interface FetchValuationResponse {
  workspaceID: number
  fb: PlatformValuation
  yt: PlatformValuation
  ig: PlatformValuation
  tiktok: PlatformValuation
}

export interface PlatformValuation {
  workspaceID: number
  platformType: PlatformType
  viewValue: number
  commentValue: number
  likeValue: number
  shareValue: number
  onlineValue: number
  platformName: string
}

export interface FetchKolRecommendResponse {
  negative: boolean
  positive: boolean
}

export interface FetchKolContentPayload {
  type?: string
  keyword?: string
  /**
   * PlatformShortcode
   */
  platform?: string
  postType?: PostType
  subPostType?: PostType
  postStartTime?: string
  postEndTime?: string
  anchor?: string
  sort?: KolPostListSorter
  size?: number
  next_paging_token?: string
  is_advertorial?: boolean
  customized_tags_names?: string[]
  post_types?: z.infer<typeof postTypeSchema>[]
}

export enum SearchKolsSort {
  Recommend = 'recommend',
  FollowerCount = 'followerCount',
  MaxFansUpRateThreeMonths = 'maxFansUpRate3Month',
  MaxInteractiveRateThreeMonths = 'maxInteractiveRate3Month',
  MaxAvgPvRateThreeMonths = 'maxAvgPvRate3Month',
  MatchedPost = 'matchedPost',
}

export interface ChatGPTSearchRequest extends BasicRequest {
  keyword: string
  anchor?: string
  sort?: SearchKolsSort
}

interface ChatGPTSearchResponse<
  DataType extends KolSchemaRemote | KolSchemaModel,
> {
  radarSearch: {
    anchor: string
    list: DataType[]
    total: number
  }
}

/** 給後端判斷hashtag資料來源，目前 billboard 來自 ai team, watchlist是我們team */
export enum HashtagSource {
  AI = 'ai',
  User = 'specific',
}

export enum HashtagReportStatus {
  Running = 'running',
  Failed = 'failed',
  Succeeded = 'succeeded',
}

export interface HashtagReport {
  createdAt: string
  /** 代表hashtag 的唯一值 */
  hash: string
  hashtag: string
  status: HashtagReportStatus
}

type FetchHashtagReportResponse = ListResource<HashtagReport>

interface AddHashtagReportRequest extends BasicRequest {
  hashtag: string
  from?: string
}

interface FetchAnalyzeHashtagsRequest extends BasicRequest {
  platformType: PlatformShortcode
  countryCode: string
  calculateRange: Duration
  customized_tags_name: string
}

export interface AnalyzeHashtag {
  /** uuid 包含搜尋條件的唯一值 */
  uuid: string
  /** 代表hashtag 的唯一值 */
  hash: string
  hashtag: string
  postCount: number
  engagementCount: number
  kolCount: number
  isFirstTimeRank: boolean
  rank: number
  latestUpdatedAt: string
}

export interface HashtagPost {
  url: string
  title: string
  engagementCount: number
  engagementRate: number
  publishedAt: string
  kolUUID: string
  name: string[]
  thumbnailUrl: string
  crawlerId: string
  postId: string
  igPostId: string
}

export interface HashtagKol {
  kolUUID: string
  name: string[]
  followerCount: number
}

export interface TrendPostCountByDay {
  day: string
  postCount: number
}

interface FetchHashtagAnalyzeDetailRequest extends FetchAnalyzeHashtagsRequest {
  hash: string
  source: HashtagSource
}

export interface FetchHashtagAnalyzeDetailResponse {
  updatedAt: string // 最後一次更新資料的時間
  createdAt: Nullable<string> // 首次進入排行榜的時間
  originCreatedAt: Nullable<string> // 被建立的時間
  uuid: string
  hashtag: string
  countryCode: string
  autoTag: string
  postCount: number
  engagementCount: number
  kolCount: number
  isFirstTimeRank: boolean
  calculateRange: string
  rank: number
  highestRank: number
  highestRankAt: Nullable<string>
  status: HashtagReportStatus
  kol: HashtagKol[]
  latestPublishedPosts: HashtagPost[]
  engagementPosts: HashtagPost[]
  trendPostCountByDay: TrendPostCountByDay[]
}

interface TriggerRefreshHashtagReportRequest extends BasicRequest {
  hash: string
}

interface CreateCollectionRequest extends BasicRequest {
  name: string
  uuid?: string
}

interface Owner {
  id: number
  name: string
}

type FetchCollectionOwnerListResponse = Owner[]

export interface Collection {
  id: number
  kolAmount: number
  lastUpdated: string
  name: string
  ownerId: number
  ownerName: string
  queryCollectedKol: Nullable<{
    collectionKolId: number
  }>
  workspaceId: number
}

export interface UpdateCollectionNameRequest extends BasicRequest {
  name: string
  collectionId: number
}

export interface QuotaInfo {
  source: QuotaSource
  quantity: number
  expiredAt: string
  nextUpdatedAt: Nullable<string>
}

interface QuotaWallet {
  remainingQuota: number
  usedQuota: number
  remainingQuotaInfo: QuotaInfo[]
}

interface FetchQuotaWalletUsageResponse {
  quota: Nullable<QuotaWallet> // enterprise will return null
}

export interface PricingPlan {
  id: number
  plan: PlanType
  amount: Nullable<string>
  duration: DurationType
  basicQuota: number
  currency: CurrencyCode
}

export enum AddOnProductType {
  Quota = 'quota',
  Report = 'report',
}

export interface AddOnProduct {
  id: number
  type: AddOnProductType
  plan: PlanType
  amount: string
  currency: CurrencyCode
  duration: DurationType
}

interface FetchAvailableProductsResponse {
  plans: PricingPlan[]
  products: AddOnProduct[]
}

export interface PredictPriceProduct {
  id: number
  quantity: number
}

export interface PredictPriceRequest extends BasicRequest {
  products: PredictPriceProduct[]
  planId: Nullable<number>
  couponCode: Nullable<string>
}

export interface PredictPriceResponse {
  orderItems: {
    type: ProductCategory
    quantity: Nullable<number>
    duration: Nullable<DurationType>
    amount: string
    discountRate: Nullable<number>
  }[]
  subtotal: string
  coupon: Nullable<{
    code: string
    amount: string
    rate: string
  }>
  fee: string
  total: string
  payNow: boolean
  serviceEndTime: string
  currency: CurrencyCode
}

// todo: 應該使用 BasicRequest
interface FetchAvailableUnlockDataRequest {
  workspaceId: number
  kolUuid: string
}

export interface FetchAvailableUnlockDataResponse {
  audienceLog: PlatformShortcode[]
  kScore: PlatformShortcode[]
  relatedKolLog: PlatformShortcode[]
  similarKolLog: PlatformShortcode[]
  statisticHashtagLog: PlatformShortcode[]
  statisticLog: PlatformShortcode[]
}

export interface FetchWorkspaceResponse {
  id: number
  name: string
  memberUsers: ModelAuthAssignment[]
  guestUsers: ModelGuestUser[]
  workspaceAuth: ModelWorkspaceAuth
  iconFile: Nullable<ModelFileItem>
}

type InviteWorkspaceMemberRequest = BasicRequest &
  AuthAssignmentSliceRequestObject

export interface Trial {
  id: number
  trialDuration: number
}

type FetchTrialResponse = Trial[]
type FetchInvoicesResponse = ModelInvoice[]

interface SetPremiumTrialRequest extends BasicRequest {
  memo: { responseId: string }
}

interface RemoveGuestRequest extends BasicRequest {
  email: string
}

interface ChangeMemberRoleRequest extends BasicRequest {
  authId: number
  role: string
}

interface RemoveMemberRequest extends BasicRequest {
  authId: number
}

interface ReadTutorialFeatureRequest extends BasicRequest {
  tutorialId: number
  isRead: boolean
}

interface RoleFunction {
  id: number
  name: string
  type: string
}

interface InvoiceUserRole extends UserRole {
  roleFunctions: RoleFunction[]
}

export interface OrderPlan {
  id: number
  userRole: InvoiceUserRole
  duration: DurationType
  displayCurrency: CurrencyCode
  display: boolean
}

export interface OrderProduct {
  id: number
  category: ProductCategory
  /*
   * @description  dependency on ProductCategory
   *
   * ProductCategory: Report, Quota
   *
   *  - format e.g. advance_tw_90_report, advance_tw_90_quota
   *
   * ProductCategory: ChangePlanDiscount, RemainReportDiscount...
   *
   *  - format e.g. change_plan_discount, remain_report_discount...
   * */
  name: string
  description: string
  currency: CurrencyCode
}

interface OrderItem {
  plan: Nullable<OrderPlan>
  product: Nullable<OrderProduct>
  amount: number
  quantity: number
  memo: string
}

interface Coupon {
  id: number
  code: string
  discount: string
  amount: number
}

export enum InvoicePaymentStatus {
  Paid = 'paid',
  Refund = 'refund',
}

interface InvoicePayContactInfo {
  email: string
  name: string
  phone: string
  address: string
  companyName: string
  zipCode: string
  taxID?: string
  isCompany: boolean
}

interface InvoiceAmounts {
  actualBillingPrice: number
  actualPayCurrency: CurrencyCode
  actualPayPrice: number
  actualReducedPrice: number
  actualSubTotal: number
  actualVatFee: number
}

interface InvoiceDetail {
  ikalaName: string
  ikalaAddress: string
  receiptAt: string
  paymentStatus: InvoicePaymentStatus
  payContactInfo: InvoicePayContactInfo
  orderItems: OrderItem[]
  coupon: Nullable<Coupon>
  businessTax: {
    amount: number
  }
  amount: InvoiceAmounts
}

export interface Invoice {
  id: string
  receiptAt: string
  detail: InvoiceDetail
}

interface FetchProductReceiptsResponse {
  page: number
  perPage: number
  total: number
  totalPage: number
  data: Invoice[]
}

export interface ExecuteSubscriptionPaymentTransactionRequest
  extends PredictPriceRequest {
  paymentProvider: PaymentProvider
  contactParameter: Omit<PaymentSchema, 'planID' | 'prime' | 'couponCode'>
  paymentTransactionParameter: TappayPaymentTransactionParameter
  salesCode: Nullable<string>
}

interface ModifyUpcomingSubscriptionRequest extends PredictPriceRequest {
  salesCode: Nullable<string>
}

export enum QuotaSource {
  Plan = 'plan',
  Purchase = 'purchase',
  AdminOperationPlan = 'admin-operation-plan',
  AdminOperationQuota = 'admin-operation-quota',
  AdminOperationTrialQuota = 'admin-operation-trial-quota',
}

interface UnlockedHistory {
  kolUUID: string
  kolName: string
  followerCount: number
  unlockedAt: string
  unlockedBy: string
  isRadarStar: boolean
  isVerified: boolean
  walletSource: QuotaSource
}

type FetchUnlockedHistoryResponse = ListResource<UnlockedHistory>

export type CreateWorkspaceRequest = WorkspaceRequestObject &
  Partial<BasicRequest>

interface CreateWorkspaceResponse {
  id: number
  name: string
}

interface Cardholder {
  phoneNumber: string
  name: string
  email: string
}

interface FetchCreditCardsResponse {
  creditCards: { id: number; cardholder: Cardholder; info: PaymentMeta }[]
}

interface DeleteCreditCardRequest extends BasicRequest {
  creditCardId: number
}

export enum RankType {
  WordOfMouthVolume = 'word_of_mouth_volume',
  EngagementRate = 'engagement_rate',
  ViewRate = 'view_rate',
  FollowCount = 'follower_count',
}

export interface InfluencersRankingRequest extends BasicRequest {
  category?: string
  platform: PlatformShortcode
  countryCode: string
  rankType: RankType
  kolBusinessType: boolean
}

export interface InfluencerRanking {
  kolId: number
  uuid: string
  name: string
  followerCount: Nullable<number>
  rank: number
  wordOfMouthVolume: Nullable<number>
  engagementRate: Nullable<number>
  viewRate: Nullable<number>
  isCollectionKol: boolean
}

interface FetchInfluencersRankingResponse {
  lastUpdatedAt: Nullable<string>
  data: InfluencerRanking[]
}

interface HashtagCheckRequest extends BasicRequest {
  hashtag: string
}

type HashtagCheckResponse = Nullable<{
  hash: string
}>

interface DeleteHashtagRequest extends BasicRequest {
  hash: string
}

export const workspacesApi = api.injectEndpoints({
  endpoints: (build) => ({
    createWorkspace: build.mutation<
      CreateWorkspaceResponse,
      CreateWorkspaceRequest
    >({
      query: ({ workspaceId, ...workspace }) => {
        const id = workspaceId ?? ''
        const url = id ? `/workspaces/${id}` : '/workspaces'

        return {
          url,
          method: 'POST',
          body: decamelizeKeys(workspace),
        }
      },
      transformResponse: (response: object) => {
        return camelizeKeys(response) as unknown as CreateWorkspaceResponse
      },
      invalidatesTags: [UserAPITagType.UserStatus, UserAPITagType.UserInfo],
    }),
    readTutorial: build.mutation<void, ReadTutorialFeatureRequest>({
      query: ({ workspaceId, tutorialId, isRead }) => {
        return {
          url: `/workspaces/${workspaceId}/tutorials/${tutorialId}`,
          method: 'PATCH',
          body: { is_read: isRead },
        }
      },
      invalidatesTags: [WorkspacesAPITagType.TutorialList],
    }),
    fetchTutorial: build.query<TutorialListResponse, BasicRequest>({
      query({ workspaceId }) {
        return {
          url: `/workspaces/${workspaceId}/tutorials`,
        }
      },
      extraOptions: {
        dataSchema: tutorialListSchema,
      },
      providesTags: [WorkspacesAPITagType.TutorialList],
    }),
    removeMember: build.mutation<void, RemoveMemberRequest>({
      query: ({ workspaceId, authId }) => {
        return {
          url: `/workspaces/${workspaceId}/authAssignments/${authId}`,
          method: 'DELETE',
        }
      },
      invalidatesTags: [WorkspacesAPITagType.Workspace],
    }),
    changeMemberRole: build.mutation<
      BrandRecognitionSnap,
      ChangeMemberRoleRequest
    >({
      query: ({ workspaceId, authId, role }) => {
        return {
          url: `/workspaces/${workspaceId}/authAssignments/${authId}`,
          method: 'PATCH',
          body: { role },
        }
      },
      invalidatesTags: [WorkspacesAPITagType.Workspace],
    }),
    removeGuest: build.mutation<void, RemoveGuestRequest>({
      query: ({ workspaceId, email }) => {
        return {
          url: `/workspaces/${workspaceId}/guestAssignments`,
          method: 'DELETE',
          body: { email },
        }
      },
      invalidatesTags: [WorkspacesAPITagType.Workspace],
    }),
    fetchInvoices: build.query<FetchInvoicesResponse, BasicRequest>({
      query: ({ workspaceId }) => {
        return {
          url: `/workspaces/${workspaceId}/invoices`,
        }
      },
      transformResponse: (response: object[]) => {
        return camelizeKeys(response) as unknown as FetchInvoicesResponse
      },
    }),
    fetchTrial: build.query<FetchTrialResponse, BasicRequest>({
      query: ({ workspaceId }) => {
        return {
          url: `/workspaces/${workspaceId}/trial`,
        }
      },
      transformResponse: (response: { data: object }) => {
        return camelizeKeys(response.data) as unknown as FetchTrialResponse
      },
      providesTags: [WorkspacesAPITagType.TrialList],
    }),
    setPremiumTrial: build.mutation<void, SetPremiumTrialRequest>({
      query: ({ workspaceId, ...body }) => {
        return {
          url: `/workspaces/${workspaceId}/trial`,
          method: 'POST',
          body,
        }
      },
      invalidatesTags: [
        UserAPITagType.UserStatus,
        WorkspacesAPITagType.QuotaWalletUsage,
        UserAPITagType.UserStatus,
        WorkspacesAPITagType.Workspace,
      ],
    }),
    changeCurrentWorkspace: build.mutation<void, BasicRequest>({
      query: ({ workspaceId }) => {
        return {
          url: `/workspaces/${workspaceId}/focus`,
          method: 'PUT',
        }
      },
      invalidatesTags: [UserAPITagType.UserStatus],
    }),
    inviteWorkspaceMember: build.mutation<void, InviteWorkspaceMemberRequest>({
      query: ({ workspaceId, ...body }) => {
        return {
          url: `/workspaces/${workspaceId}/authAssignments`,
          method: 'POST',
          body,
        }
      },
      invalidatesTags: [WorkspacesAPITagType.Workspace],
    }),
    fetchWorkspace: build.query<FetchWorkspaceResponse, BasicRequest>({
      query: ({ workspaceId }) => {
        return {
          url: `/workspaces/${workspaceId}`,
        }
      },
      transformResponse: (response: object[]) => {
        return camelizeKeys(response) as unknown as FetchWorkspaceResponse
      },
      providesTags: [WorkspacesAPITagType.Workspace],
    }),
    deleteWorkspace: build.mutation<void, BasicRequest>({
      query: ({ workspaceId }) => {
        return {
          url: `/workspaces/${workspaceId}`,
          method: 'DELETE',
        }
      },
      invalidatesTags: [UserAPITagType.UserStatus],
    }),
    leaveWorkspace: build.mutation<void, BasicRequest>({
      query: ({ workspaceId }) => {
        return {
          url: `/workspaces/${workspaceId}/leaving`,
          method: 'DELETE',
        }
      },
      invalidatesTags: [UserAPITagType.UserStatus],
    }),
    fetchSubscriptionCases: build.query<SubscriptionCaseResponse, number>({
      query: (workspaceId) => {
        return {
          url: `/workspaces/${workspaceId}/subscription-cases`,
        }
      },
      providesTags: [WorkspacesAPITagType.SubscriptionCases],
      transformResponse: (response: SubscriptionCaseResponse) => {
        const camelizeResponse = camelizeKeys(
          response,
        ) as unknown as SubscriptionCaseResponse

        camelizeResponse.data = camelizeResponse?.data.map((x) => {
          const [workspaceType, countryCode] = x.plan.userRole.code.split('_')

          return set(x, 'plan.userRole', {
            ...x.plan.userRole,
            workspaceType,
            countryCode,
          })
        })

        return camelizeResponse
      },
    }),
    cancelSubscription: build.mutation<void, SubscriptionCaseRequest>({
      query: ({ workspaceId, subscriptionCaseID }) => {
        return {
          url: `/workspaces/${workspaceId}/subscription-cases/${subscriptionCaseID}`,
          method: 'DELETE',
        }
      },
      invalidatesTags: [WorkspacesAPITagType.SubscriptionCases],
    }),
    cancelSubscriptionItems: build.mutation<void, SubscriptionCaseItemRequest>({
      query: ({ workspaceId, subscriptionCaseID, ...params }) => {
        return {
          url: `/workspaces/${workspaceId}/subscription-cases/${subscriptionCaseID}/subscription-case-items`,
          method: 'DELETE',
          body: decamelizeKeys(params),
        }
      },
      invalidatesTags: [WorkspacesAPITagType.SubscriptionCases],
    }),
    restoreSubscription: build.mutation<void, SubscriptionCaseRequest>({
      query: ({ workspaceId, subscriptionCaseID }) => {
        return {
          url: `/workspaces/${workspaceId}/subscription-cases/${subscriptionCaseID}/restore`,
          method: 'POST',
        }
      },
      invalidatesTags: [WorkspacesAPITagType.SubscriptionCases],
    }),
    updatePaymentSetting: build.mutation<void, PaymentSettingRequest>({
      query: ({
        workspaceId,
        subscriptionCaseID,
        paymentSettingID,
        ...paymentSettings
      }) => {
        const body = omit(paymentSettings, ['couponCode', 'prime', 'planID'])
        return {
          url: `/workspaces/${workspaceId}/subscription-cases/${subscriptionCaseID}/payment-setting/${paymentSettingID}`,
          method: 'PUT',
          body: decamelizeKeys(body),
        }
      },
      invalidatesTags: [WorkspacesAPITagType.SubscriptionCases],
    }),
    fetchGoogleAnalyticsSetting: build.query<GoogleAnalyticsSetting, number>({
      query: (workspaceId) => {
        return `/workspaces/${workspaceId}/ga4-setting`
      },
      providesTags: [WorkspacesAPITagType.GoogleAnalyticsSetting],
      transformResponse: (response: object) => {
        return camelizeKeys(response) as unknown as GoogleAnalyticsSetting
      },
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled
          dispatch(
            googleAnalyticsAdminApi.util.invalidateTags([
              GoogleAnalyticsAdminAPITagType.AccountSummaries,
            ]),
          )
        } catch (error) {
          console.error(error)
        }
      },
    }),
    setGoogleAnalyticsSetting: build.mutation<
      void,
      SetGoogleAnalyticsSettingRequest
    >({
      query: ({ workspaceId, ...body }) => {
        return {
          url: `/workspaces/${workspaceId}/ga4-setting`,
          method: 'PUT',
          body: decamelizeKeys(body),
        }
      },
      invalidatesTags: [WorkspacesAPITagType.GoogleAnalyticsSetting],
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled
          dispatch(
            googleAnalyticsAdminApi.util.invalidateTags([
              GoogleAnalyticsAdminAPITagType.PropertySummary,
            ]),
          )
        } catch (error) {
          console.error(error)
        }
      },
    }),
    removeGoogleAnalyticsSetting: build.mutation<void, number>({
      query: (workspaceId) => {
        return {
          url: `/workspaces/${workspaceId}/ga4-setting`,
          method: 'DELETE',
        }
      },
      invalidatesTags: [WorkspacesAPITagType.GoogleAnalyticsSetting],
    }),
    checkIfHashtagIsDuplicated: build.mutation<
      HashtagCheckResponse,
      HashtagCheckRequest
    >({
      queryFn: async (
        { workspaceId, ...body },
        _queryApi,
        _extraOptions,
        baseQuery,
      ) => {
        const result = await baseQuery({
          url: `/workspaces/${workspaceId}/analyze/hashtags/detections/exist`,
          method: 'POST',
          body: decamelizeKeys(body),
        })

        if (result.error?.status === 409) {
          return {
            data: result.error.data as HashtagCheckResponse,
          }
        }

        return {
          data: null,
        }
      },
    }),
    deleteHashtag: build.mutation<null, DeleteHashtagRequest>({
      query: ({ workspaceId, hash }) => {
        return {
          url: `/workspaces/${workspaceId}/analyze/hashtags/detections/${hash}`,
          method: 'DELETE',
        }
      },
      invalidatesTags: [
        WorkspacesAPITagType.HashtagWatchlist,
        WorkspacesAPITagType.HashtagAnalyzeDetail,
      ],
    }),
    executeCampaignKolAction: build.mutation<
      CampaignInvitationResponse,
      ExecuteCampaignKolActionRequest
    >({
      query: ({ workspaceId, campaignID, ...params }) => {
        return {
          url: `/workspaces/${workspaceId}/campaigns/${campaignID}/campaign-kol-actions`,
          method: 'POST',
          body: decamelizeKeys(params),
        }
      },
      invalidatesTags: (result, error, arg) => {
        return arg.action === CampaignKolAction.Invite
          ? [
              WorkspacesAPITagType.Campaign,
              WorkspacesAPITagType.CampaignKolList,
              UserAPITagType.PubNubAccessToken,
            ]
          : [
              WorkspacesAPITagType.Campaign,
              WorkspacesAPITagType.CampaignKolList,
            ]
      },
      transformResponse(response: object) {
        return camelizeKeys(response) as unknown as CampaignInvitationResponse
      },
    }),
    chatgptSearch: build.query<
      ChatGPTSearchResponse<KolSchemaModel>,
      ChatGPTSearchRequest
    >({
      query: ({ workspaceId, ...params }) => {
        return {
          url: `/workspaces/${workspaceId}/chatgpt-search`,
          params: removeNil(params),
        }
      },
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName
      },
      transformResponse: (response: object) => {
        const camelizeResponse = camelizeKeys(
          response,
        ) as unknown as ChatGPTSearchResponse<KolSchemaRemote>

        return {
          ...camelizeResponse,
          radarSearch: {
            ...camelizeResponse.radarSearch,
            list: camelizeResponse.radarSearch.list.map((kol) =>
              applyKolSchemaModel(kol),
            ),
          },
        }
      },
      merge: (currentCache, newResponse, args) => {
        if (!isEmpty(args.arg.anchor)) {
          currentCache.radarSearch.anchor = newResponse.radarSearch.anchor
          currentCache.radarSearch.list.push(...newResponse.radarSearch.list)
        } else {
          currentCache.radarSearch = newResponse.radarSearch
        }
      },
      forceRefetch({ currentArg, previousArg }) {
        return !isEqual(currentArg, previousArg)
      },
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          updateSearchId()
          await queryFulfilled
        } catch (error) {
          console.error(error)
        }
      },
    }),
    fetchRecommendKolList: build.query<RecommendKOLResponse, CampaignRequest>({
      query: ({ workspaceId, campaignID, ...params }) => {
        return {
          url: `/workspaces/${workspaceId}/campaigns/${campaignID}/recommended-kols`,
          params: removeNil(params),
        }
      },
      transformResponse: (response: object) => {
        return camelizeKeys(response) as unknown as RecommendKOLResponse
      },
      providesTags: [WorkspacesAPITagType.RecommendKolList],
    }),
    fetchRecommendKol: build.query<RecommendKOL, CampaignKolRequest>({
      query: ({ workspaceId, campaignID, kolID }) => {
        return {
          url: `/workspaces/${workspaceId}/campaigns/${campaignID}/searched-kols/${kolID} `,
        }
      },
      transformResponse: (response: object) => {
        return camelizeKeys(response) as unknown as RecommendKOL
      },
      providesTags: [WorkspacesAPITagType.RecommendKol],
    }),
    fetchBrandRecognitions: build.query<BrandRecognitionSnap, number>({
      query: (workspaceId) => {
        return `/workspaces/${workspaceId}/brand-recognitions`
      },
      transformResponse: (response: object) => {
        return camelizeKeys(response) as unknown as BrandRecognitionSnap
      },
      providesTags: [WorkspacesAPITagType.BrandRecognition],
    }),
    updateBrandRecognitions: build.mutation<
      BrandRecognitionSnap,
      UpdateBrandRecognitionsRequest
    >({
      query: ({ workspaceId, ...body }) => {
        return {
          url: `/workspaces/${workspaceId}/brand-recognitions`,
          method: 'PATCH',
          body: decamelizeKeys(body),
        }
      },
      transformResponse: (response: object) => {
        return camelizeKeys(response) as unknown as BrandRecognitionSnap
      },
      invalidatesTags: [WorkspacesAPITagType.BrandRecognition],
    }),
    createCampaign: build.mutation<Campaign, CreateCampaignRequest>({
      query: ({ workspaceId, ...body }) => {
        return {
          url: `/workspaces/${workspaceId}/campaigns`,
          method: 'POST',
          body: decamelizeKeys(body),
        }
      },
      transformResponse: (response: object) => {
        return camelizeKeys(response) as unknown as Campaign
      },
      invalidatesTags: [
        WorkspacesAPITagType.CampaignList,
        {
          type: WorkspacesAPITagType.FilterResources,
          id: filterResourceType.enum.campaign,
        },
      ],
    }),
    fetchCampaignList: build.query<
      ListResource<Campaign>,
      FetchCampaignListRequest
    >({
      query: ({ workspaceId, ...params }) => {
        return {
          url: `/workspaces/${workspaceId}/campaigns`,
          params: decamelizeKeys(params),
        }
      },
      transformResponse: (response: object) => {
        return camelizeKeys(response) as unknown as ListResource<Campaign>
      },
      providesTags: [WorkspacesAPITagType.CampaignList],
    }),
    fetchCampaign: build.query<Campaign, FetchCampaignRequest>({
      query: ({ workspaceId, campaignID }) => {
        return `/workspaces/${workspaceId}/campaigns/${campaignID}`
      },
      transformResponse: (response: object) => {
        return camelizeKeys(response) as unknown as Campaign
      },
      providesTags: [WorkspacesAPITagType.Campaign],
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const response = await queryFulfilled
          dispatch(fetchCampaignSuccess(response.data))
        } catch (error) {
          console.error(error)
        }
      },
    }),
    updateCampaign: build.mutation<Campaign, UpdateCampaignRequest>({
      query: ({ workspaceId, campaignID, campaign }) => {
        return {
          url: `/workspaces/${workspaceId}/campaigns/${campaignID}`,
          method: 'PUT',
          body: decamelizeKeys(campaign),
        }
      },
      invalidatesTags: [
        WorkspacesAPITagType.Campaign,
        WorkspacesAPITagType.CampaignList,
        WorkspacesAPITagType.RecommendKolList,
        {
          type: WorkspacesAPITagType.FilterResources,
          id: filterResourceType.enum.campaign,
        },
      ],
    }),
    updateCampaignRemark: build.mutation<Campaign, UpdateCampaignRemarkRequest>(
      {
        query: ({ workspaceId, campaignID, remark }) => {
          return {
            url: `/workspaces/${workspaceId}/campaigns/${campaignID}`,
            method: 'PATCH',
            body: { remark },
          }
        },
        invalidatesTags: [WorkspacesAPITagType.CampaignList],
      },
    ),
    updateCampaignBrandRecognition: build.mutation<
      Campaign,
      UpdateCampaignBrandRecognitionRequest
    >({
      query: ({ workspaceId, campaignId, brandRecognitionSnap }) => {
        return {
          url: `/workspaces/${workspaceId}/campaigns/${campaignId}`,
          method: 'PATCH',
          body: decamelizeKeys({ brandRecognitionSnap }),
        }
      },
      invalidatesTags: [WorkspacesAPITagType.Campaign],
    }),
    deleteCampaign: build.mutation<Campaign, DeleteCampaignRequest>({
      query: ({ workspaceId, campaignID }) => {
        return {
          url: `/workspaces/${workspaceId}/campaigns/${campaignID}`,
          method: 'DELETE',
        }
      },
      invalidatesTags: [
        WorkspacesAPITagType.CampaignList,
        {
          type: WorkspacesAPITagType.FilterResources,
          id: filterResourceType.enum.campaign,
        },
      ],
    }),
    fetchCampaignKolList: build.query<
      ListResource<CampaignKol>,
      FetchCampaignKolListRequest
    >({
      query: ({ workspaceId, campaignId, ...params }) => {
        return {
          url: `/workspaces/${workspaceId}/campaigns/${campaignId}/campaign-kols`,
          params: decamelizeKeys(params),
        }
      },
      providesTags: [WorkspacesAPITagType.CampaignKolList],
      transformResponse: (response: object) => {
        return camelizeKeys(response) as unknown as ListResource<CampaignKol>
      },
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const response = await queryFulfilled
          dispatch(getCampaignKolsSuccess(response.data))
        } catch (error) {
          console.error(error)
        }
      },
    }),
    removeCampaignKols: build.mutation<void, RemoveCampaignKolsRequest>({
      query: ({ workspaceId, campaignId, ...body }) => {
        return {
          url: `/workspaces/${workspaceId}/campaigns/${campaignId}/campaign-kols`,
          method: 'DELETE',
          body: decamelizeKeys(body),
        }
      },
      invalidatesTags: [
        WorkspacesAPITagType.Campaign,
        WorkspacesAPITagType.CampaignKolList,
      ],
    }),
    updateCampaignKols: build.mutation<void, UpdateCampaignKolsRequest>({
      query: ({ workspaceId, campaignId, ...body }) => {
        return {
          url: `/workspaces/${workspaceId}/campaigns/${campaignId}/campaign-kols`,
          method: 'PATCH',
          body: decamelizeKeys(body),
        }
      },
      invalidatesTags: [WorkspacesAPITagType.CampaignKolList],
    }),
    addCampaignKols: build.mutation<void, AddCampaignKolsRequest>({
      query: ({ workspaceId, campaignId, ...body }) => {
        return {
          url: `/workspaces/${workspaceId}/campaigns/${campaignId}/campaign-kols`,
          method: 'POST',
          body: decamelizeKeys(body),
        }
      },
      invalidatesTags: [
        WorkspacesAPITagType.Campaign,
        WorkspacesAPITagType.CampaignKolList,
        WorkspacesAPITagType.RecommendKolList,
        WorkspacesAPITagType.RecommendKol,
        WorkspacesAPITagType.KolManagement,
        WorkspacesAPITagType.KolCollectionKols,
      ],
    }),
    appropriationKols: build.mutation<
      BatchUpdateKolsResponse,
      AppropriationsRequest
    >({
      query: ({ workspaceId, campaignId, ...body }) => {
        return {
          url: `/workspaces/${workspaceId}/campaigns/${campaignId}/appropriations`,
          method: 'POST',
          body: decamelizeKeys(body),
        }
      },
      invalidatesTags: [
        WorkspacesAPITagType.CampaignKolList,
        WorkspacesAPITagType.RecommendKolList,
        WorkspacesAPITagType.RecommendKol,
      ],
      transformResponse: (response: object) => {
        return camelizeKeys(response) as unknown as BatchUpdateKolsResponse
      },
    }),
    replyComplaints: build.mutation<BatchUpdateKolsResponse, ComplaintsRequest>(
      {
        query: ({ workspaceId, campaignId, ...body }) => {
          return {
            url: `/workspaces/${workspaceId}/campaigns/${campaignId}/complaints`,
            method: 'POST',
            body: decamelizeKeys(body),
          }
        },
        invalidatesTags: [WorkspacesAPITagType.CampaignKolList],
        transformResponse: (response: object) => {
          return camelizeKeys(response) as unknown as BatchUpdateKolsResponse
        },
      },
    ),
    updateKolUtmInfo: build.mutation<void, UpdateUtmRequest>({
      query: ({ workspaceId, campaignId, ...body }) => {
        return {
          url: `/workspaces/${workspaceId}/campaigns/${campaignId}/utm-info-bulk-updates`,
          method: 'POST',
          body: decamelizeKeys(body),
        }
      },
      invalidatesTags: [WorkspacesAPITagType.CampaignKolList],
    }),
    fetchPaymentTransactionList: build.query<
      ListResource<PaymentTransaction>,
      FetchPaymentTransactionListRequest
    >({
      query: ({ workspaceId, ...params }) => {
        return {
          url: `/workspaces/${workspaceId}/payment-transactions`,
          params: decamelizeKeys(params),
        }
      },
      transformResponse: (response: object) => {
        return camelizeKeys(
          response,
        ) as unknown as ListResource<PaymentTransaction>
      },
    }),
    executeCampaignPaymentTransaction: build.mutation<
      TappayPaymentTransactionResponse,
      ExecuteCampaignPaymentTransactionRequest
    >({
      query: ({ workspaceId, campaignId, ...body }) => {
        return {
          url: `/workspaces/${workspaceId}/campaigns/${campaignId}/payment-transactions`,
          method: 'POST',
          body: decamelizeKeys(body),
        }
      },
      transformResponse: (response: object) => {
        return camelizeKeys(
          response,
        ) as unknown as TappayPaymentTransactionResponse
      },
      invalidatesTags: [
        WorkspacesAPITagType.Campaign,
        WorkspacesAPITagType.CampaignKolList,
        WorkspacesAPITagType.CampaignPaymentSetting,
      ],
    }),
    verifyCampaignPaymentTransaction: build.mutation<
      CampaignPaymentVerifyResponse,
      TappayPaymentTransactionVerifyRequest
    >({
      query: ({ workspaceId, ...body }) => {
        return {
          url: `/workspaces/${workspaceId}/pay/tappay/campaign-paid`,
          method: 'POST',
          body: decamelizeKeys(body),
        }
      },
      transformResponse: (response: object) => {
        return camelizeKeys(
          response,
        ) as unknown as CampaignPaymentVerifyResponse
      },
    }),
    fetchCampaignPaymentTransaction: build.query<
      FetchCampaignPaymentTransactionResponse,
      FetchCampaignPaymentTransactionRequest
    >({
      query: ({ workspaceId, campaignId, ...params }) => {
        return {
          url: `/workspaces/${workspaceId}/campaigns/${campaignId}/payment-transactions/new`,
          params: decamelizeKeys(params),
        }
      },
      transformResponse: (response: object) => {
        return camelizeKeys(
          response,
        ) as unknown as FetchCampaignPaymentTransactionResponse
      },
    }),
    fetchCampaignPaymentSetting: build.query<
      FetchCampaignPaymentSettingResponse,
      FetchCampaignPaymentSettingRequest
    >({
      query: ({ workspaceId, campaignId }) => {
        return `/workspaces/${workspaceId}/campaigns/${campaignId}/payment-setting`
      },
      transformResponse: (response: object) => {
        return camelizeKeys(
          response,
        ) as unknown as FetchCampaignPaymentSettingResponse
      },
      providesTags: [WorkspacesAPITagType.CampaignPaymentSetting],
    }),
    subscribeCrawlerStatus: build.mutation<null, SubscribeCrawlerStatusRequest>(
      {
        query: ({ kolUUID, platform }) => {
          const baseUrl = `/kols/${kolUUID}/subscribe`
          const url = platform ? `${baseUrl}/${platform}` : baseUrl

          return {
            url: getWorkspaceApiUrl(url),
            method: 'POST',
          }
        },
        transformResponse: (response: object) => {
          return camelizeKeys(response) as unknown as null
        },
        invalidatesTags: [WorkspacesAPITagType.Subscription],
      },
    ),
    fetchValuation: build.query<FetchValuationResponse, BasicRequest>({
      query: ({ workspaceId }) => {
        return {
          url: `/workspaces/${workspaceId}/valueEstimation`,
        }
      },
      transformResponse: (response: object) => {
        return camelizeKeys(response) as unknown as FetchValuationResponse
      },
    }),
    fetchKolRecommend: build.query<
      FetchKolRecommendResponse,
      FetchKOLResourceRequest
    >({
      query: ({ workspaceId, kolUUID }) => {
        return {
          url: `/workspaces/${workspaceId}/irm-recommend/${kolUUID}`,
        }
      },
      transformResponse: (response: object) => {
        return camelizeKeys(response) as unknown as FetchKolRecommendResponse
      },
    }),
    fetchHashtagReport: build.query<
      FetchHashtagReportResponse,
      PaginatedBasicRequest
    >({
      query: ({ workspaceId, ...params }) => {
        return {
          url: `/workspaces/${workspaceId}/analyze/hashtags/detections`,
          params: decamelizeKeys(params),
        }
      },
      ...infiniteConfigSetting<
        PaginatedBasicRequest,
        FetchHashtagReportResponse
      >(),
      transformResponse: (response: object) => {
        return camelizeKeys(response) as unknown as FetchHashtagReportResponse
      },
      providesTags: [WorkspacesAPITagType.HashtagWatchlist],
    }),
    addHashtagReport: build.mutation<void, AddHashtagReportRequest>({
      query: ({ workspaceId, hashtag, from }) => {
        return {
          url: `/workspaces/${workspaceId}/analyze/hashtags/detections`,
          method: 'POST',
          body: decamelizeKeys({ hashtag, from }),
        }
      },
      invalidatesTags: [
        WorkspacesAPITagType.HashtagWatchlist,
        WorkspacesAPITagType.CompetitiveBrandAnalysesHashTagList,
        WorkspacesAPITagType.CompetitiveMentionAnalysesHashTagList,
        WorkspacesAPITagType.CompetitiveKeywordAnalysisHashtagList,
      ],
    }),
    triggerRefreshHashtagReport: build.mutation<
      void,
      TriggerRefreshHashtagReportRequest
    >({
      query: ({ workspaceId, hash }) => {
        return {
          url: `/workspaces/${workspaceId}/analyze/hashtags/detections/${hash}/refresh`,
          method: 'POST',
        }
      },
      invalidatesTags: [
        WorkspacesAPITagType.HashtagWatchlist,
        WorkspacesAPITagType.HashtagAnalyzeDetail,
      ],
    }),
    fetchCollectionOwners: build.query<
      FetchCollectionOwnerListResponse,
      BasicRequest
    >({
      query: ({ workspaceId }) => {
        return {
          url: `/workspaces/${workspaceId}/collection-owners`,
        }
      },
      transformResponse: (response: object[]) => {
        return camelizeKeys(
          response,
        ) as unknown as FetchCollectionOwnerListResponse
      },
    }),
    fetchAnalyzeHashtags: build.query<
      ListResource<AnalyzeHashtag>,
      FetchAnalyzeHashtagsRequest
    >({
      query: ({ workspaceId, ...payload }) => {
        const params = decamelizeKeys(removeNil(payload))

        return {
          url: `/workspaces/${workspaceId}/analyze/hashtags`,
          params,
        }
      },
      transformResponse: (response: object) => {
        return camelizeKeys(response) as unknown as ListResource<AnalyzeHashtag>
      },
    }),
    fetchHashtagAnalyzeDetail: build.query<
      FetchHashtagAnalyzeDetailResponse,
      FetchHashtagAnalyzeDetailRequest
    >({
      query: ({ workspaceId, hash, ...payload }) => {
        const params = decamelizeKeys(removeNil(payload))
        return {
          url: `/workspaces/${workspaceId}/analyze/hashtags/${hash}`,
          params,
        }
      },
      providesTags: [WorkspacesAPITagType.HashtagAnalyzeDetail],
      transformResponse: (response: object) => {
        return camelizeKeys(
          response,
        ) as unknown as FetchHashtagAnalyzeDetailResponse
      },
    }),
    fetchQuotaWalletUsage: build.query<
      FetchQuotaWalletUsageResponse,
      BasicRequest
    >({
      query: ({ workspaceId }) => {
        return {
          url: `/workspaces/${workspaceId}/quota-wallet/usage`,
        }
      },
      providesTags: [WorkspacesAPITagType.QuotaWalletUsage],
      transformResponse: (response: object) => {
        return camelizeKeys(
          response,
        ) as unknown as FetchQuotaWalletUsageResponse
      },
    }),
    fetchAvailableProducts: build.query<
      FetchAvailableProductsResponse,
      BasicRequest
    >({
      query: ({ workspaceId }) =>
        `/workspaces/${workspaceId}/available-products`,
      transformResponse: (response: object) => {
        return camelizeKeys(
          response,
        ) as unknown as FetchAvailableProductsResponse
      },
    }),
    predictPrice: build.query<PredictPriceResponse, PredictPriceRequest>({
      query: ({ workspaceId, ...payload }) => {
        return {
          url: `/workspaces/${workspaceId}/predict-price`,
          method: 'POST',
          body: decamelizeKeys(payload),
        }
      },
      transformResponse: (response: object) => {
        return camelizeKeys(response) as unknown as PredictPriceResponse
      },
    }),
    fetchAvailableUnlockData: build.query<
      FetchAvailableUnlockDataResponse,
      FetchAvailableUnlockDataRequest
    >({
      query: ({ workspaceId, kolUuid }) => {
        return `/workspaces/${workspaceId}/kols/${kolUuid}/available-unlock-data`
      },
      transformResponse: (response: object) => {
        return camelizeKeys(
          response,
        ) as unknown as FetchAvailableUnlockDataResponse
      },
    }),
    fetchProductReceipts: build.query<
      FetchProductReceiptsResponse,
      PaginatedBasicRequest
    >({
      query: ({ workspaceId, ...query }) => {
        return {
          url: `/workspaces/${workspaceId}/product-receipts`,
          params: decamelizeKeys(query),
        }
      },
      transformResponse: (response: object) => {
        return camelizeKeys(response) as unknown as FetchProductReceiptsResponse
      },
      providesTags: [WorkspacesAPITagType.ProductReceipts],
    }),
    executeSubscriptionPaymentTransaction: build.mutation<
      ExecuteSubscriptionPaymentTransactionResponse,
      ExecuteSubscriptionPaymentTransactionRequest
    >({
      query: ({ workspaceId, ...body }) => {
        return {
          url: `/workspaces/${workspaceId}/checkout`,
          method: 'POST',
          body: decamelizeKeys(omit(body, 'paymentProvider')),
        }
      },
      extraOptions: {
        dataSchema: executeSubscriptionPaymentTransactionResponseSchema,
      },
      invalidatesTags: (result, error, arg) => {
        return arg.paymentTransactionParameter.useRemembered &&
          arg.paymentProvider === PaymentProvider.Tappay
          ? [
              UserAPITagType.UserStatus,
              WorkspacesAPITagType.QuotaWalletUsage,
              WorkspacesAPITagType.SubscriptionCases,
              WorkspacesAPITagType.ProductReceipts,
            ]
          : []
      },
    }),
    verifySubscriptionPaymentTransaction: build.mutation<
      VerifySubscriptionPaymentTransactionResponse,
      VerifySubscriptionPaymentTransactionRequest
    >({
      query: ({ workspaceId, ...body }) => {
        return {
          url: `/workspaces/${workspaceId}/subscription-checkout/verify`,
          method: 'POST',
          body,
        }
      },
      extraOptions: {
        dataSchema: verifySubscriptionPaymentTransactionResponseSchema,
      },
      invalidatesTags: [
        UserAPITagType.UserStatus,
        WorkspacesAPITagType.QuotaWalletUsage,
        WorkspacesAPITagType.SubscriptionCases,
      ],
    }),
    modifyUpcomingSubscription: build.mutation<
      void,
      ModifyUpcomingSubscriptionRequest
    >({
      query: ({ workspaceId, ...body }) => {
        return {
          url: `/workspaces/${workspaceId}/subscription-cases`,
          method: 'PATCH',
          body: decamelizeKeys(body),
        }
      },
      invalidatesTags: [WorkspacesAPITagType.SubscriptionCases],
    }),
    fetchUnlockedHistory: build.query<
      FetchUnlockedHistoryResponse,
      PaginatedBasicRequest
    >({
      query: ({ workspaceId, ...params }) => {
        return {
          url: `/workspaces/${workspaceId}/unlocked-history`,
          params,
        }
      },
      transformResponse: (response: object) => {
        return camelizeKeys(response) as unknown as FetchUnlockedHistoryResponse
      },
      ...infiniteConfigSetting<
        PaginatedBasicRequest,
        FetchUnlockedHistoryResponse
      >(),
    }),
    fetchCreditCards: build.query<FetchCreditCardsResponse, BasicRequest>({
      query: ({ workspaceId }) => {
        return {
          url: `/workspaces/${workspaceId}/credit-cards`,
        }
      },
      transformResponse: (response: object) => {
        return camelizeKeys(response) as unknown as FetchCreditCardsResponse
      },
      providesTags: [WorkspacesAPITagType.CreditCards],
    }),
    bindCreditCard: build.mutation<
      BindCreditCardResponse,
      BindCreditCardRequest
    >({
      query: ({ workspaceId, ...body }) => {
        return {
          url: `/workspaces/${workspaceId}/credit-card`,
          method: 'POST',
          body,
        }
      },
      extraOptions: {
        dataSchema: bindCreditCardResponseSchema,
      },
    }),
    deleteCreditCard: build.mutation<
      TappayPaymentTransactionResponse,
      DeleteCreditCardRequest
    >({
      query: ({ workspaceId, creditCardId }) => {
        return {
          url: `/workspaces/${workspaceId}/credit-cards/${creditCardId}`,
          method: 'DELETE',
        }
      },
      invalidatesTags: [WorkspacesAPITagType.CreditCards],
    }),
    verifyCreditCard: build.mutation<
      VerifyCreditCardResponse,
      VerifyCreditCardRequest
    >({
      query: ({ workspaceId, ...body }) => {
        return {
          url: `/workspaces/${workspaceId}/pay/verify/credit-card`,
          method: 'POST',
          body,
        }
      },
      extraOptions: {
        dataSchema: verifyCreditCardResponseSchema,
      },
      invalidatesTags: [WorkspacesAPITagType.CreditCards],
    }),
    fetchAnalysisRanking: build.query<
      FetchInfluencersRankingResponse,
      InfluencersRankingRequest
    >({
      query: ({ workspaceId, ...params }) => {
        return {
          url: `/workspaces/${workspaceId}/analyze/ranking`,
          params: decamelizeKeys(omitBy(params, isUndefined)),
        }
      },
      transformResponse: (response: object) => {
        return camelizeKeys(
          response,
        ) as unknown as FetchInfluencersRankingResponse
      },
      providesTags: [WorkspacesAPITagType.InfluencerRanking],
    }),
    fetchPostCollectionList: build.query<
      PostCollectionList,
      FetchPostCollectionListRequest
    >({
      query: ({ workspaceId, ...params }) => {
        return {
          url: `/workspaces/${workspaceId}/post-collections`,
          params: decamelizeKeys(removeNil(params)),
        }
      },
      extraOptions: {
        dataSchema: postCollectionListSchema,
      },
      providesTags: [WorkspacesAPITagType.PostCollectionList],
    }),
    fetchInfinitePostCollectionList: build.query<
      PostCollectionList,
      FetchPostCollectionListRequest
    >({
      query: ({ workspaceId, ...params }) => {
        return {
          url: `/workspaces/${workspaceId}/post-collections`,
          params: decamelizeKeys(removeNil(omit(params, 'reset'))),
        }
      },
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName
      },
      merge: (currentCache, newResponse, { arg }): void => {
        const updateCache = {
          ...omit(newResponse, 'data'),
          data:
            newResponse.page === 1 || arg.reset
              ? newResponse.data
              : [...currentCache.data, ...newResponse.data],
        }

        Object.assign(currentCache, updateCache)
      },
      forceRefetch({ currentArg, previousArg }): boolean {
        return !isEqual(currentArg, previousArg)
      },
      extraOptions: {
        dataSchema: postCollectionListSchema,
      },
    }),
    fetchPostCollection: build.query<
      FetchPostCollectionResponse,
      BasicPostCollectionRequest
    >({
      query: ({ id, workspaceId }) => {
        return {
          url: `/workspaces/${workspaceId}/post-collections/${id}`,
        }
      },
      extraOptions: {
        dataSchema: postCollectionResponseSchema,
      },
      providesTags: [WorkspacesAPITagType.PostCollection],
    }),
    fetchPost: build.query<FetchPostResponse, FetchPostRequest>({
      query: ({ workspaceId, ...params }) => {
        return {
          url: `/workspaces/${workspaceId}/post`,
          method: 'GET',
          params,
        }
      },
      extraOptions: {
        dataSchema: postResponseSchema,
      },
    }),
    modifyPostCollection: build.mutation<void, ModifyPostCollectionRequest>({
      query: ({ id, workspaceId, ...body }) => {
        return {
          url: `/workspaces/${workspaceId}/post-collections/${id}`,
          method: 'PATCH',
          body,
        }
      },
      invalidatesTags: [
        WorkspacesAPITagType.PostCollection,
        WorkspacesAPITagType.PostCollectionList,
      ],
    }),
    exportPostCollection: build.query<ArrayBuffer, BasicPostCollectionRequest>({
      query: ({ id, workspaceId }) => {
        return {
          url: `/workspaces/${workspaceId}/post-collections/${id}/export`,
          responseHandler: (response): Promise<ArrayBuffer> =>
            response.arrayBuffer(),
        }
      },
    }),
    duplicatePostCollection: build.mutation<
      void,
      DuplicatePostCollectionRequest
    >({
      query: ({ id, workspaceId, name }) => {
        return {
          url: `/workspaces/${workspaceId}/post-collections/${id}/duplicate`,
          method: 'POST',
          body: { name },
        }
      },
      invalidatesTags: [WorkspacesAPITagType.PostCollectionList],
    }),
    deletePostCollection: build.mutation<void, BasicPostCollectionRequest>({
      query: ({ id, workspaceId }) => {
        return {
          url: `/workspaces/${workspaceId}/post-collections/${id}`,
          method: 'DELETE',
        }
      },
      invalidatesTags: [
        WorkspacesAPITagType.PostCollectionList,
        WorkspacesAPITagType.PostRadarSearch,
      ],
    }),
    createPostCollection: build.mutation<
      { id: number },
      CreateCollectionRequest
    >({
      query: ({ workspaceId, ...body }) => {
        return {
          url: `/workspaces/${workspaceId}/post-collections`,
          method: 'POST',
          body,
        }
      },
      invalidatesTags: [WorkspacesAPITagType.PostCollectionList],
    }),
    addCollectionPost: build.mutation<{ id: number }, AddCollectionPostRequest>(
      {
        query: ({ workspaceId, collectionId, ...payload }) => {
          const body = decamelizeKeys({ ...payload, source: 'app' })

          return {
            url: `/workspaces/${workspaceId}/post-collections/${collectionId}/posts`,
            method: 'Post',
            body,
          }
        },
        async onQueryStarted(
          { postId },
          { getState, dispatch, queryFulfilled },
        ) {
          try {
            await queryFulfilled
            updatePostCollectionPostQueryData(
              getState() as RootState,
              dispatch,
              postId,
              true,
            )
          } catch (error) {
            logger.error(error)
          }
        },
        invalidatesTags: [
          WorkspacesAPITagType.PostCollectionList,
          WorkspacesAPITagType.PostCollectionPostList,
        ],
      },
    ),
    deleteCollectionPost: build.mutation<void, DeleteCollectionPostRequest>({
      query: ({ workspaceId, collectionId, collectionPostId }) => {
        return {
          url: `/workspaces/${workspaceId}/post-collections/${collectionId}/posts/${collectionPostId}`,
          method: 'DELETE',
        }
      },
      async onQueryStarted({ postId }, { getState, dispatch, queryFulfilled }) {
        try {
          await queryFulfilled
          updatePostCollectionPostQueryData(
            getState() as RootState,
            dispatch,
            postId,
            false,
          )
        } catch (error) {
          logger.error(error)
        }
      },
      invalidatesTags: [WorkspacesAPITagType.PostCollectionList],
    }),
    fetchPostCollectionPostList: build.query<
      FetchPostCollectionPostListResponse,
      FetchPostCollectionPostListRequest
    >({
      queryFn: async (
        { id, workspaceId, ...params },
        _queryApi,
        _extraOptions,
        baseQuery,
      ) => {
        // API 目前只支援這三個平台的查詢 https://www.notion.so/ikala/BE-API-List-dc18f9e223a34e618578c0b1dfbf6175?pvs=4
        const platform = [
          PlatformShortcode.YouTube,
          PlatformShortcode.Facebook,
          PlatformShortcode.Instagram,
        ].includes(params.platform as PlatformShortcode)
          ? params.platform
          : undefined
        return (await baseQuery({
          url: `/workspaces/${workspaceId}/post-collections/${id}/posts`,
          params: removeNil({ ...params, platform }),
        })) as { data: FetchPostCollectionPostListResponse }
      },
      extraOptions: {
        dataSchema: fetchPostCollectionPostListResponseSchema,
      },
      providesTags: [WorkspacesAPITagType.PostCollectionPostList],
    }),
    deletePostCollectionPost: build.mutation<
      void,
      BasicPostCollectionPostRequest
    >({
      queryFn: async (
        { id, workspaceId, post_collection_id },
        _queryApi,
        _extraOptions,
        baseQuery,
      ) => {
        return (await baseQuery({
          url: `/workspaces/${workspaceId}/post-collections/${post_collection_id}/posts/${id}`,
          method: 'DELETE',
        })) as { data: void }
      },
      invalidatesTags: [
        WorkspacesAPITagType.PostCollectionPostList,
        WorkspacesAPITagType.PostRadarSearch,
      ],
    }),
    fetchPostCollectionOwnerList: build.query<
      PostCollectionOwnerList,
      BasicRequest
    >({
      query: ({ workspaceId }) => {
        return `/workspaces/${workspaceId}/post-collection-owners`
      },
      extraOptions: {
        dataSchema: postCollectionOwnerListSchema,
      },
    }),
    triggerPostCollectionPostCrawler: build.mutation<
      void,
      BasicPostCollectionPostRequest
    >({
      queryFn: async (
        { id, workspaceId, post_collection_id },
        _queryApi,
        _extraOptions,
        baseQuery,
      ) => {
        return (await baseQuery({
          url: `/workspaces/${workspaceId}/post-collections/${post_collection_id}/posts/${id}/trigger-crawler`,
          method: 'GET',
        })) as { data: void }
      },
      invalidatesTags: [WorkspacesAPITagType.PostCollectionPostList],
    }),
    redeemTrialCode: build.mutation<void, RedeemTrialCodeRequest>({
      query: ({ workspaceId, code }) => {
        return {
          url: `/workspaces/${workspaceId}/trial-codes/${code}`,
          method: 'POST',
        }
      },
      invalidatesTags: [
        UserAPITagType.UserStatus,
        WorkspacesAPITagType.TrialList,
      ],
    }),
    deleteQueuedTrialCodes: build.mutation<void, BasicRequest>({
      query: ({ workspaceId }) => {
        return {
          url: `/workspaces/${workspaceId}/trial-codes/queued-trial-codes`,
          method: 'DELETE',
        }
      },
      invalidatesTags: [UserAPITagType.UserStatus],
    }),
  }),
  overrideExisting: false,
})

export const useCreateWorkspaceMutation =
  workspacesApi.endpoints.createWorkspace.useMutation
export const useRemoveMemberMutation =
  workspacesApi.endpoints.removeMember.useMutation
export const useChangeMemberRoleMutation =
  workspacesApi.endpoints.changeMemberRole.useMutation
export const useRemoveGuestMutation =
  workspacesApi.endpoints.removeGuest.useMutation
export const useSetPremiumTrialMutation =
  workspacesApi.endpoints.setPremiumTrial.useMutation
export const useFetchInvoicesQuery =
  workspacesApi.endpoints.fetchInvoices.useQuery
export const useChangeCurrentWorkspaceNameMutation =
  workspacesApi.endpoints.changeCurrentWorkspace.useMutation
export const useInviteWorkspaceMemberMutation =
  workspacesApi.endpoints.inviteWorkspaceMember.useMutation
export const useFetchWorkspaceQuery =
  workspacesApi.endpoints.fetchWorkspace.useQuery
export const useDeleteWorkspaceMutation =
  workspacesApi.endpoints.deleteWorkspace.useMutation
export const useLeaveWorkspaceMutation =
  workspacesApi.endpoints.leaveWorkspace.useMutation
export const useFetchSubscriptionCasesQuery =
  workspacesApi.endpoints.fetchSubscriptionCases.useQuery
export const useCancelSubscriptionMutation =
  workspacesApi.endpoints.cancelSubscription.useMutation
export const useUpdatePaymentSettingMutation =
  workspacesApi.endpoints.updatePaymentSetting.useMutation
export const useFetchGoogleAnalyticsSettingQuery =
  workspacesApi.endpoints.fetchGoogleAnalyticsSetting.useQuery
export const useSetGoogleAnalyticsSettingMutation =
  workspacesApi.endpoints.setGoogleAnalyticsSetting.useMutation
export const useRemoveGoogleAnalyticsSettingMutation =
  workspacesApi.endpoints.removeGoogleAnalyticsSetting.useMutation
export const useExecuteCampaignKolActionMutation =
  workspacesApi.endpoints.executeCampaignKolAction.useMutation
export const useFetchRecommendKolListQuery =
  workspacesApi.endpoints.fetchRecommendKolList.useQuery
export const useFetchRecommendKolQuery =
  workspacesApi.endpoints.fetchRecommendKol.useQuery
export const useFetchBrandRecognitionsQuery =
  workspacesApi.endpoints.fetchBrandRecognitions.useQuery
export const useUpdateBrandRecognitionsMutation =
  workspacesApi.endpoints.updateBrandRecognitions.useMutation
export const useCreateCampaignMutation =
  workspacesApi.endpoints.createCampaign.useMutation
export const useFetchCampaignListQuery =
  workspacesApi.endpoints.fetchCampaignList.useQuery
export const useFetchCampaignQuery =
  workspacesApi.endpoints.fetchCampaign.useQuery
export const useLazyFetchCampaignQuery =
  workspacesApi.endpoints.fetchCampaign.useLazyQuery
export const useUpdateCampaignMutation =
  workspacesApi.endpoints.updateCampaign.useMutation
export const useUpdateCampaignRemarkMutation =
  workspacesApi.endpoints.updateCampaignRemark.useMutation
export const useUpdateCampaignBrandRecognitionMutation =
  workspacesApi.endpoints.updateCampaignBrandRecognition.useMutation
export const useDeleteCampaignMutation =
  workspacesApi.endpoints.deleteCampaign.useMutation
export const useFetchCampaignKolListQuery =
  workspacesApi.endpoints.fetchCampaignKolList.useQuery
export const useRemoveCampaignKolsMutation =
  workspacesApi.endpoints.removeCampaignKols.useMutation
export const useUpdateCampaignKolsMutation =
  workspacesApi.endpoints.updateCampaignKols.useMutation
export const useAddCampaignKolsMutation =
  workspacesApi.endpoints.addCampaignKols.useMutation
export const useAppropriationKolsMutation =
  workspacesApi.endpoints.appropriationKols.useMutation
export const useReplyComplaintsMutation =
  workspacesApi.endpoints.replyComplaints.useMutation
export const useUpdateKolUtmInfoMutation =
  workspacesApi.endpoints.updateKolUtmInfo.useMutation
export const useFetchPaymentTransactionListQuery =
  workspacesApi.endpoints.fetchPaymentTransactionList.useQuery
export const useExecuteCampaignPaymentTransactionMutation =
  workspacesApi.endpoints.executeCampaignPaymentTransaction.useMutation
export const useVerifyCampaignPaymentTransactionMutation =
  workspacesApi.endpoints.verifyCampaignPaymentTransaction.useMutation
export const useFetchCampaignPaymentTransactionQuery =
  workspacesApi.endpoints.fetchCampaignPaymentTransaction.useQuery
export const useFetchCampaignPaymentSettingQuery =
  workspacesApi.endpoints.fetchCampaignPaymentSetting.useQuery
export const useSubscribeCrawlerStatusMutation =
  workspacesApi.endpoints.subscribeCrawlerStatus.useMutation
export const useFetchValuationQuery =
  workspacesApi.endpoints.fetchValuation.useQuery
export const useFetchKolRecommendQuery =
  workspacesApi.endpoints.fetchKolRecommend.useQuery
export const useLazyChatGPTSearchQuery =
  workspacesApi.endpoints.chatgptSearch.useLazyQuery
export const useFetchAnalyzeHashtagsQuery =
  workspacesApi.endpoints.fetchAnalyzeHashtags.useQuery
export const useFetchHashtagReportQuery =
  workspacesApi.endpoints.fetchHashtagReport.useQuery
export const useCheckIfHashtagIsDuplicatedMutation =
  workspacesApi.endpoints.checkIfHashtagIsDuplicated.useMutation
export const useDeleteHashtagMutation =
  workspacesApi.endpoints.deleteHashtag.useMutation
export const useAddHashtagReportMutation =
  workspacesApi.endpoints.addHashtagReport.useMutation
export const useTriggerRefreshHashtagReportMutation =
  workspacesApi.endpoints.triggerRefreshHashtagReport.useMutation
export const useFetchHashtagAnalyzeDetailQuery =
  workspacesApi.endpoints.fetchHashtagAnalyzeDetail.useQuery
export const useFetchCollectionOwnersQuery =
  workspacesApi.endpoints.fetchCollectionOwners.useQuery
export const useFetchQuotaWalletUsageQuery =
  workspacesApi.endpoints.fetchQuotaWalletUsage.useQuery
export const useFetchAvailableProductsQuery =
  workspacesApi.endpoints.fetchAvailableProducts.useQuery
export const usePredictPriceQuery =
  workspacesApi.endpoints.predictPrice.useQuery
export const useLazyPredictPriceQuery =
  workspacesApi.endpoints.predictPrice.useLazyQuery
export const useRestoreSubscriptionMutation =
  workspacesApi.endpoints.restoreSubscription.useMutation
export const useCancelSubscriptionItemsMutation =
  workspacesApi.endpoints.cancelSubscriptionItems.useMutation
export const useFetchAvailableUnlockDataQuery =
  workspacesApi.endpoints.fetchAvailableUnlockData.useQuery
export const useFetchProductReceiptsQuery =
  workspacesApi.endpoints.fetchProductReceipts.useQuery
export const useExecuteSubscriptionPaymentTransactionMutation =
  workspacesApi.endpoints.executeSubscriptionPaymentTransaction.useMutation
export const useModifyUpcomingSubscriptionMutation =
  workspacesApi.endpoints.modifyUpcomingSubscription.useMutation
export const useFetchUnlockedHistoryQuery =
  workspacesApi.endpoints.fetchUnlockedHistory.useQuery
export const useFetchCreditCardsQuery =
  workspacesApi.endpoints.fetchCreditCards.useQuery
export const useDeleteCreditCardMutation =
  workspacesApi.endpoints.deleteCreditCard.useMutation
export const useFetchAnalysisRankingQuery =
  workspacesApi.endpoints.fetchAnalysisRanking.useQuery
export const useFetchPostCollectionListQuery =
  workspacesApi.endpoints.fetchPostCollectionList.useQuery
export const useFetchPostCollectionQuery =
  workspacesApi.endpoints.fetchPostCollection.useQuery
export const useModifyPostCollectionMutation =
  workspacesApi.endpoints.modifyPostCollection.useMutation
export const useLazyExportPostCollectionQuery =
  workspacesApi.endpoints.exportPostCollection.useLazyQuery
export const useDuplicatePostCollectionMutation =
  workspacesApi.endpoints.duplicatePostCollection.useMutation
export const useDeletePostCollectionMutation =
  workspacesApi.endpoints.deletePostCollection.useMutation
export const useCreatePostCollectionMutation =
  workspacesApi.endpoints.createPostCollection.useMutation
export const useAddCollectionPostMutation =
  workspacesApi.endpoints.addCollectionPost.useMutation
export const useDeleteCollectionPostMutation =
  workspacesApi.endpoints.deleteCollectionPost.useMutation
export const useLazyFetchInfinitePostCollectionListQuery =
  workspacesApi.endpoints.fetchInfinitePostCollectionList.useLazyQuery
export const useFetchPostCollectionPostListQuery =
  workspacesApi.endpoints.fetchPostCollectionPostList.useQuery
export const useDeletePostCollectionPostMutation =
  workspacesApi.endpoints.deletePostCollectionPost.useMutation
export const useFetchPostCollectionOwnerListQuery =
  workspacesApi.endpoints.fetchPostCollectionOwnerList.useQuery
export const useTriggerPostCollectionPostCrawlerMutation =
  workspacesApi.endpoints.triggerPostCollectionPostCrawler.useMutation

export const {
  useFetchTrialQuery,
  useRedeemTrialCodeMutation,
  useDeleteQueuedTrialCodesMutation,
  useBindCreditCardMutation,
  useVerifyCreditCardMutation,
  useVerifySubscriptionPaymentTransactionMutation,
  useFetchTutorialQuery,
  useReadTutorialMutation,
  useFetchPostQuery,
} = workspacesApi
