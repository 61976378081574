import { defaultSerializeQueryArgs } from '@reduxjs/toolkit/query/react'
import { isEmpty, isEqual, omit } from 'lodash-es'
import { WorkspacesAPITagType, api, infiniteConfigSetting } from '@/api/api'
import getWorkspaceApiUrl from '@/api/utils/get-workspace-api-url'
import defaultStringifyRequest from '@/constants/default-stringify-request'
import type { FetchKolCollectionSearchFieldsRequest } from '@/types/schema/directory/kol-collection'
import {
  AddCollaborationRecordScoringRequest,
  AddKolContactInfoRequest,
  AddNoteRequest,
  AddQuotationRequest,
  DeleteCollaborationRecordScoringRequest,
  DeleteKolContactInfoRequest,
  DeleteNoteRequest,
  DeleteQuotationRequest,
  DirectoryCustomizedSearchLeafField,
  DirectorySearchFields,
  DirectorySearchFieldsResponse,
  EditCollaborationRecordScoringRequest,
  EditKolBasicInfoRequest,
  EditKolContactInfoRequest,
  EditQuotationRequest,
  FetchCollaborationRecordScoringRequest,
  FetchCollaborationRecordScoringResponse,
  FetchCustomizedFieldsRequest,
  FetchCustomizedFieldsResponse,
  FetchCustomizedLabelsResponse,
  FetchFilterResourcesRequest,
  FetchFilterResourcesResponse,
  FetchKolBasicInfoRequest,
  FetchKolBasicInfoResponse,
  FetchKolContactInfoRequest,
  FetchKolContactInfoResponse,
  FetchKolFootprintRequest,
  FetchKolFootprintResponse,
  FetchKolManagementRequest,
  FetchKolManagementResponse,
  FetchNoteRequest,
  FetchNoteResponse,
  FetchQuotationRequest,
  FetchQuotationResponse,
  KolCollectionBasicRequest,
  Note,
  UpdateCustomizedFieldValueRequest,
  addKolsToDirectoryResponseSchema,
  directoryCustomizedSearchLeafFieldSchema,
  directorySearchFieldsResponseSchema,
  fetchCollaborationRecordScoringSchema,
  fetchCustomizedFieldsSchema,
  fetchCustomizedLabelsResponseSchema,
  fetchFilterResourcesResponseSchema,
  fetchKolBasicInfoResponseSchema,
  fetchKolContactInfoResponseSchema,
  fetchKolFootprintResponseSchema,
  fetchKolManagementCollaborationRecordsResponseSchema,
  fetchKolManagementResponseSchema,
  fetchNoteResponseSchema,
  fetchQuotationResponseSchema,
  kolManagementStatusSchema,
  type AddKolsToDirectoryRequest,
  type AddKolsToDirectoryResponse,
  type ChangeKolCollectionCustomizedFieldRequest,
  type CreateKolCollectionCustomizedFieldRequest,
  type DeleteKolCollectionCustomizedFieldRequest,
  type FetchKolManagementCollaborationRecordsRequest,
  type FetchKolManagementCollaborationRecordsResponse,
  type KolManagementStatus,
} from '@/types/schema/directory/kol-management'
import removeNil from '@/utils/remove-nil'

const directoryApi = api.injectEndpoints({
  endpoints: (builder) => ({
    fetchKolManagement: builder.query<
      FetchKolManagementResponse,
      FetchKolManagementRequest
    >({
      query: (params) => {
        return {
          url: getWorkspaceApiUrl('/directory/kol-management', 'v3'),
          params: removeNil({ ...params, ...defaultStringifyRequest }),
        }
      },
      extraOptions: {
        dataSchema: fetchKolManagementResponseSchema,
      },
      providesTags: [WorkspacesAPITagType.KolManagement],
    }),
    addKolsToDirectory: builder.mutation<
      AddKolsToDirectoryResponse,
      AddKolsToDirectoryRequest
    >({
      query: (body) => {
        return {
          url: getWorkspaceApiUrl('/directory/kol-management', 'v3'),
          method: 'POST',
          body,
        }
      },
      extraOptions: {
        dataSchema: addKolsToDirectoryResponseSchema,
      },
      invalidatesTags: [
        WorkspacesAPITagType.KolManagement,
        WorkspacesAPITagType.KolManagementStatus,
      ],
    }),
    removeKolsFromDirectory: builder.mutation<void, string[]>({
      query: (ids) => {
        return {
          url: getWorkspaceApiUrl('/directory/kol-management', 'v3'),
          method: 'DELETE',
          body: ids,
        }
      },
      invalidatesTags: [
        WorkspacesAPITagType.KolInfo,
        WorkspacesAPITagType.KolManagement,
      ],
    }),
    fetchCollaborationRecordScoring: builder.query<
      FetchCollaborationRecordScoringResponse,
      FetchCollaborationRecordScoringRequest
    >({
      query: ({ kolId }) => ({
        // @todo: use /kols/{kolUUID}/directory/collaboration-record-scorings v3
        url: getWorkspaceApiUrl(`/irm-cooperation-scoring/${kolId}`),
      }),
      extraOptions: {
        dataSchema: fetchCollaborationRecordScoringSchema,
      },
      providesTags: [WorkspacesAPITagType.DirectoryCollaborationRecordScoring],
    }),
    deleteCollaborationRecordScoring: builder.mutation<
      void,
      DeleteCollaborationRecordScoringRequest
    >({
      query: ({ id }) => ({
        // @todo: use /kols/{kolUUID}/directory/collaboration-record-scorings v3
        url: getWorkspaceApiUrl(`/irm-cooperation-scoring/${id}`),
        method: 'DELETE',
      }),
      invalidatesTags: [
        WorkspacesAPITagType.DirectoryCollaborationRecordScoring,
        WorkspacesAPITagType.KolManagement,
      ],
    }),
    addCollaborationRecordScoring: builder.mutation<
      void,
      AddCollaborationRecordScoringRequest
    >({
      query: ({ kolId, score }) => ({
        // @todo: use /kols/{kolUUID}/directory/collaboration-record-scorings v3
        url: getWorkspaceApiUrl(`/irm-cooperation-scoring/${kolId}`),
        method: 'POST',
        body: score,
      }),
      invalidatesTags: [
        WorkspacesAPITagType.DirectoryCollaborationRecordScoring,
        WorkspacesAPITagType.KolManagement,
      ],
    }),
    editCollaborationRecordScoring: builder.mutation<
      void,
      EditCollaborationRecordScoringRequest
    >({
      query: ({ id, score }) => ({
        url: getWorkspaceApiUrl(`/irm-cooperation-scoring/${id}`),
        method: 'PATCH',
        body: score,
      }),
      invalidatesTags: [
        WorkspacesAPITagType.DirectoryCollaborationRecordScoring,
        WorkspacesAPITagType.KolManagement,
      ],
    }),
    fetchQuotation: builder.query<
      FetchQuotationResponse,
      FetchQuotationRequest
    >({
      query: ({ kolId }) => ({
        // @todo: use /directory/quotation v3
        url: getWorkspaceApiUrl(`/irm-quotation-info/${kolId}`),
        method: 'GET',
      }),
      extraOptions: {
        dataSchema: fetchQuotationResponseSchema,
      },
      providesTags: [WorkspacesAPITagType.DirectoryQuotation],
    }),
    deleteQuotation: builder.mutation<void, DeleteQuotationRequest>({
      query: ({ id }) => ({
        // @todo: use /directory/quotation v3
        url: getWorkspaceApiUrl(`/irm-quotation-info/${id}`),
        method: 'DELETE',
      }),
      invalidatesTags: [WorkspacesAPITagType.DirectoryQuotation],
    }),
    addQuotation: builder.mutation<void, AddQuotationRequest>({
      query: ({ kolId, quotation }) => ({
        // @todo: use /directory/quotation v3
        url: getWorkspaceApiUrl(`/irm-quotation-info/${kolId}`),
        method: 'POST',
        body: quotation,
      }),
      invalidatesTags: [WorkspacesAPITagType.DirectoryQuotation],
    }),
    editQuotation: builder.mutation<void, EditQuotationRequest>({
      query: ({ quotation, id }) => ({
        // @todo: use /directory/quotation v3
        url: getWorkspaceApiUrl(`/irm-quotation-info/${id}`),
        method: 'PATCH',
        body: quotation,
      }),
      invalidatesTags: [WorkspacesAPITagType.DirectoryQuotation],
    }),
    fetchNote: builder.query<FetchNoteResponse, FetchNoteRequest>({
      query: ({ kolId }) => ({
        url: getWorkspaceApiUrl(`/irm-cooperation-note/${kolId}`),
      }),
      extraOptions: {
        dataSchema: fetchNoteResponseSchema,
      },
      providesTags: [WorkspacesAPITagType.DirectoryNote],
    }),
    deleteNote: builder.mutation<void, DeleteNoteRequest>({
      query: ({ id }) => ({
        url: getWorkspaceApiUrl(`/irm-cooperation-note/${id}`),
        method: 'DELETE',
      }),
      invalidatesTags: [WorkspacesAPITagType.DirectoryNote],
    }),
    addNote: builder.mutation<Note, AddNoteRequest>({
      query: ({ kolId, note }) => ({
        url: getWorkspaceApiUrl(`/irm-cooperation-note/${kolId}`),
        method: 'POST',
        body: note,
      }),
      invalidatesTags: [WorkspacesAPITagType.DirectoryNote],
    }),
    editNote: builder.mutation<void, Note>({
      query: ({ ID, ...body }) => ({
        url: getWorkspaceApiUrl(`/irm-cooperation-note/${ID}`),
        method: 'PATCH',
        body,
      }),
      invalidatesTags: [WorkspacesAPITagType.DirectoryNote],
    }),
    fetchDirectorySearchFields: builder.query<
      DirectorySearchFieldsResponse,
      void
    >({
      query: () => getWorkspaceApiUrl('/directory/search-fields', 'v3'),
      extraOptions: {
        dataSchema: directorySearchFieldsResponseSchema,
      },
      providesTags: [WorkspacesAPITagType.DirectorySearchFields],
    }),
    createDirectoryCustomizedField: builder.mutation<
      DirectoryCustomizedSearchLeafField,
      string
    >({
      query: (type) => {
        return {
          url: getWorkspaceApiUrl('/directory/customized-fields', 'v3'),
          method: 'POST',
          body: { type },
        }
      },
      extraOptions: {
        dataSchema: directoryCustomizedSearchLeafFieldSchema,
      },
    }),
    changeDirectoryCustomizedField: builder.mutation<
      void,
      Pick<DirectoryCustomizedSearchLeafField, 'id' | 'type'>
    >({
      query: ({ id, type }) => {
        return {
          url: getWorkspaceApiUrl(`/directory/customized-fields/${id}`, 'v3'),
          method: 'PATCH',
          body: { type },
        }
      },
      invalidatesTags: [
        WorkspacesAPITagType.DirectorySearchFields,
        WorkspacesAPITagType.KolManagement,
      ],
    }),
    deleteDirectoryCustomizedField: builder.mutation<void, number>({
      query: (customizedFieldId) => {
        return {
          url: getWorkspaceApiUrl(
            `/directory/customized-fields/${customizedFieldId}`,
            'v3',
          ),
          method: 'DELETE',
        }
      },
      invalidatesTags: [
        WorkspacesAPITagType.DirectorySearchFields,
        WorkspacesAPITagType.KolManagement,
      ],
    }),
    updateDirectorySearchFields: builder.mutation<void, DirectorySearchFields>({
      query: (body) => {
        return {
          url: getWorkspaceApiUrl(`/directory/search-fields`, 'v3'),
          method: 'PUT',
          body,
        }
      },
      invalidatesTags: [
        WorkspacesAPITagType.DirectorySearchFields,
        WorkspacesAPITagType.KolManagement,
      ],
    }),
    fetchKolCollectionSearchFields: builder.query<
      DirectorySearchFieldsResponse,
      FetchKolCollectionSearchFieldsRequest
    >({
      query: ({ id }) =>
        getWorkspaceApiUrl(
          `/directory/kol-collections/${id}/search-fields`,
          'v3',
        ),
      extraOptions: {
        dataSchema: directorySearchFieldsResponseSchema,
      },
      providesTags: [WorkspacesAPITagType.KolCollectionSearchFields],
    }),
    updateKolCollectionSearchFields: builder.mutation<
      void,
      KolCollectionBasicRequest & DirectorySearchFields
    >({
      query: ({ collectionId, ...body }) => {
        return {
          url: getWorkspaceApiUrl(
            `/directory/kol-collections/${collectionId}/search-fields`,
            'v3',
          ),
          method: 'PUT',
          body,
        }
      },
      invalidatesTags: [
        WorkspacesAPITagType.KolCollectionKols,
        WorkspacesAPITagType.KolCollectionSearchFields,
      ],
    }),
    fetchKolContactInfos: builder.query<
      FetchKolContactInfoResponse,
      FetchKolContactInfoRequest
    >({
      query: ({ kolId }) => ({
        url: getWorkspaceApiUrl(`/kols/${kolId}/directory/contact-infos`, 'v3'),
      }),
      extraOptions: {
        dataSchema: fetchKolContactInfoResponseSchema,
      },
      providesTags: [WorkspacesAPITagType.DirectoryKolContactInfo],
    }),
    addKolContactInfo: builder.mutation<void, AddKolContactInfoRequest>({
      query: ({ kolId, contactInfo }) => ({
        url: getWorkspaceApiUrl(`/kols/${kolId}/directory/contact-info`, 'v3'),
        method: 'POST',
        body: contactInfo,
      }),
      invalidatesTags: [
        WorkspacesAPITagType.DirectoryKolContactInfo,
        WorkspacesAPITagType.KolManagement,
        WorkspacesAPITagType.KolCollectionKols,
      ],
    }),
    editKolContactInfo: builder.mutation<void, EditKolContactInfoRequest>({
      query: ({ kolId, contactInfoId, contactInfo }) => ({
        url: getWorkspaceApiUrl(
          `/kols/${kolId}/directory/contact-infos/${contactInfoId}`,
          'v3',
        ),
        method: 'PATCH',
        body: contactInfo,
      }),
      invalidatesTags: [
        WorkspacesAPITagType.DirectoryKolContactInfo,
        WorkspacesAPITagType.KolManagement,
        WorkspacesAPITagType.KolCollectionKols,
      ],
    }),
    deleteKolContactInfo: builder.mutation<void, DeleteKolContactInfoRequest>({
      query: ({ kolId, contactInfoId }) => ({
        url: getWorkspaceApiUrl(
          `/kols/${kolId}/directory/contact-infos/${contactInfoId}`,
          'v3',
        ),
        method: 'DELETE',
      }),
      invalidatesTags: [
        WorkspacesAPITagType.DirectoryKolContactInfo,
        WorkspacesAPITagType.KolManagement,
      ],
    }),
    fetchFilterResources: builder.query<
      FetchFilterResourcesResponse,
      FetchFilterResourcesRequest
    >({
      query: (params) => ({
        url: getWorkspaceApiUrl(`/filter-resources`, 'v3'),
        params: removeNil(params),
      }),
      extraOptions: {
        dataSchema: fetchFilterResourcesResponseSchema,
      },
      providesTags: (_result, _error, arg) => [
        {
          type: WorkspacesAPITagType.FilterResources,
          id: arg.filter_resource_type,
        },
      ],
    }),
    fetchFilterResourcesInfinite: builder.query<
      FetchFilterResourcesResponse,
      FetchFilterResourcesRequest
    >({
      query: (params) => ({
        url: getWorkspaceApiUrl(`/filter-resources`, 'v3'),
        params: removeNil(params),
      }),
      ...infiniteConfigSetting<
        FetchFilterResourcesRequest,
        FetchFilterResourcesResponse
      >(),
      extraOptions: {
        dataSchema: fetchFilterResourcesResponseSchema,
      },
      providesTags: (_result, _error, arg) => [
        {
          type: WorkspacesAPITagType.FilterResources,
          id: arg.filter_resource_type,
        },
      ],
    }),
    fetchDirectoryKolBasicInfo: builder.query<
      FetchKolBasicInfoResponse,
      FetchKolBasicInfoRequest
    >({
      query: ({ kolId }) => ({
        url: getWorkspaceApiUrl(`/directory/kols/${kolId}/basic-info`, 'v3'),
      }),
      extraOptions: {
        dataSchema: fetchKolBasicInfoResponseSchema,
      },
      providesTags: [WorkspacesAPITagType.DirectoryKolBasicInfo],
    }),
    editDirectoryKolBasicInfo: builder.mutation<void, EditKolBasicInfoRequest>({
      query: ({ kolId, basicInfo }) => ({
        url: getWorkspaceApiUrl(`/directory/kols/${kolId}/basic-info`, 'v3'),
        method: 'PUT',
        body: basicInfo,
      }),
      invalidatesTags: [
        WorkspacesAPITagType.DirectoryKolBasicInfo,
        WorkspacesAPITagType.KolManagement,
        WorkspacesAPITagType.KolCollectionKols,
      ],
    }),
    fetchCustomizedLabels: builder.query<FetchCustomizedLabelsResponse, void>({
      query: () => getWorkspaceApiUrl(`/customized-labels`, 'v3'),
      extraOptions: {
        dataSchema: fetchCustomizedLabelsResponseSchema,
      },
      providesTags: [WorkspacesAPITagType.CustomizedLabels],
    }),
    createCustomizedLabel: builder.mutation<void, string>({
      query: (customized_label) => {
        return {
          url: getWorkspaceApiUrl('/customized-label', 'v3'),
          method: 'POST',
          body: { customized_label },
        }
      },
      invalidatesTags: [WorkspacesAPITagType.CustomizedLabels],
    }),
    fetchCustomizedFields: builder.query<
      FetchCustomizedFieldsResponse,
      FetchCustomizedFieldsRequest
    >({
      query: ({ kolId, field_type }) => ({
        url: getWorkspaceApiUrl(
          `/kols/${kolId}/directory/customized-fields`,
          'v3',
        ),
        params: { field_type },
      }),
      extraOptions: {
        dataSchema: fetchCustomizedFieldsSchema,
      },
      providesTags: [WorkspacesAPITagType.DirectoryKolCustomizedFields],
    }),
    updateCustomizedFieldValue: builder.mutation<
      void,
      UpdateCustomizedFieldValueRequest
    >({
      query: ({ kolId, payload }) => ({
        url: getWorkspaceApiUrl(
          `/directory/kol-management/${kolId}/customized-field`,
          'v3',
        ),
        method: 'PUT',
        body: payload,
      }),
      invalidatesTags: [
        WorkspacesAPITagType.DirectoryKolCustomizedFields,
        WorkspacesAPITagType.KolManagement,
        WorkspacesAPITagType.KolCollectionKols,
      ],
    }),
    fetchInfiniteKolFootprint: builder.query<
      FetchKolFootprintResponse,
      FetchKolFootprintRequest
    >({
      query: ({ kolId, footprint_resource_type, next_page }) => ({
        url: getWorkspaceApiUrl(`/kols/${kolId}/directory/footprints`, 'v3'),
        params: removeNil({ footprint_resource_type, next_page }),
      }),
      extraOptions: {
        dataSchema: fetchKolFootprintResponseSchema,
      },
      // todo : 統一 infinite config setting https://www.notion.so/ikala/infinity-config-setting-138fb0834d6e80b1a1c7e80dc41b1580?pvs=4
      serializeQueryArgs: ({
        endpointName,
        queryArgs,
        endpointDefinition,
      }): string => {
        return defaultSerializeQueryArgs({
          endpointName,
          queryArgs: omit(queryArgs, ['next_page']),
          endpointDefinition,
        })
      },
      merge: (currentCache, newResponse, { arg }): void => {
        const updateCache = {
          data: {
            ...newResponse.data,
            footprints: !isEmpty(arg.next_page)
              ? [
                  ...currentCache.data.footprints,
                  ...newResponse.data.footprints,
                ]
              : newResponse.data.footprints,
          },
        }
        Object.assign(currentCache, updateCache)
      },
      forceRefetch({ currentArg, previousArg }): boolean {
        return !isEqual(currentArg, previousArg)
      },
      providesTags: [
        {
          type: WorkspacesAPITagType.DirectoryKolFootprint,
        },
      ],
    }),
    fetchDirectoryKolCollaborationRecords: builder.query<
      FetchKolManagementCollaborationRecordsResponse,
      FetchKolManagementCollaborationRecordsRequest
    >({
      query: ({ kolId, ...params }) => ({
        url: getWorkspaceApiUrl(
          `/directory/kol-management/${kolId}/collaboration-records`,
          'v3',
        ),
        params,
      }),
      extraOptions: {
        dataSchema: fetchKolManagementCollaborationRecordsResponseSchema,
      },
    }),
    createKolCollectionCustomizedField: builder.mutation<
      DirectoryCustomizedSearchLeafField,
      CreateKolCollectionCustomizedFieldRequest
    >({
      query: ({ collectionId, type }) => {
        return {
          url: getWorkspaceApiUrl(
            `/directory/kol-collections/${collectionId}/customized-fields`,
            'v3',
          ),
          method: 'POST',
          body: { type },
        }
      },
      extraOptions: {
        dataSchema: directoryCustomizedSearchLeafFieldSchema,
      },
      invalidatesTags: [WorkspacesAPITagType.CollectionSettings],
    }),
    deleteKolCollectionCustomizedField: builder.mutation<
      void,
      DeleteKolCollectionCustomizedFieldRequest
    >({
      query: ({ collectionId, id }) => {
        return {
          url: getWorkspaceApiUrl(
            `/directory/kol-collections/${collectionId}/customized-fields/${id}`,
            'v3',
          ),
          method: 'DELETE',
        }
      },
      invalidatesTags: [
        WorkspacesAPITagType.KolCollectionKols,
        WorkspacesAPITagType.KolCollectionSearchFields,
        WorkspacesAPITagType.CollectionSettings,
      ],
    }),
    changeKolCollectionCustomizedField: builder.mutation<
      void,
      ChangeKolCollectionCustomizedFieldRequest
    >({
      query: ({ collectionId, id, type }) => {
        return {
          url: getWorkspaceApiUrl(
            `/directory/kol-collections/${collectionId}/customized-fields/${id}`,
            'v3',
          ),
          method: 'PATCH',
          body: { type },
        }
      },
      invalidatesTags: [
        WorkspacesAPITagType.KolCollectionKols,
        WorkspacesAPITagType.KolCollectionSearchFields,
        WorkspacesAPITagType.CollectionSettings,
      ],
    }),
    fetchKolManagementStatus: builder.query<KolManagementStatus, void>({
      query: () => getWorkspaceApiUrl('/directory/kol-management/status', 'v3'),
      extraOptions: {
        dataSchema: kolManagementStatusSchema,
      },
      providesTags: [WorkspacesAPITagType.KolManagementStatus],
    }),
  }),
  overrideExisting: false,
})

export const invalidateDirectoryKolFootprintAction =
  directoryApi.util.invalidateTags([WorkspacesAPITagType.DirectoryKolFootprint])

export const {
  useFetchKolManagementQuery,
  useAddKolsToDirectoryMutation,
  useRemoveKolsFromDirectoryMutation,
  useFetchCollaborationRecordScoringQuery,
  useDeleteCollaborationRecordScoringMutation,
  useAddCollaborationRecordScoringMutation,
  useEditCollaborationRecordScoringMutation,
  useFetchQuotationQuery,
  useDeleteQuotationMutation,
  useAddQuotationMutation,
  useEditQuotationMutation,
  useFetchNoteQuery,
  useDeleteNoteMutation,
  useAddNoteMutation,
  useEditNoteMutation,
  useFetchDirectorySearchFieldsQuery,
  useCreateDirectoryCustomizedFieldMutation,
  useChangeDirectoryCustomizedFieldMutation,
  useDeleteDirectoryCustomizedFieldMutation,
  useUpdateDirectorySearchFieldsMutation,
  useFetchKolCollectionSearchFieldsQuery,
  useUpdateKolCollectionSearchFieldsMutation,
  useFetchKolContactInfosQuery,
  useAddKolContactInfoMutation,
  useEditKolContactInfoMutation,
  useDeleteKolContactInfoMutation,
  useLazyFetchFilterResourcesQuery,
  useFetchFilterResourcesInfiniteQuery,
  useFetchDirectoryKolBasicInfoQuery,
  useEditDirectoryKolBasicInfoMutation,
  useFetchCustomizedLabelsQuery,
  useCreateCustomizedLabelMutation,
  useFetchCustomizedFieldsQuery,
  useUpdateCustomizedFieldValueMutation,
  useFetchInfiniteKolFootprintQuery,
  useFetchDirectoryKolCollaborationRecordsQuery,
  useCreateKolCollectionCustomizedFieldMutation,
  useDeleteKolCollectionCustomizedFieldMutation,
  useChangeKolCollectionCustomizedFieldMutation,
  useFetchKolManagementStatusQuery,
} = directoryApi
