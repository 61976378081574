import { Checkbox } from 'antd'
import { CheckboxChangeEvent } from 'antd/lib/checkbox/Checkbox'
import CheckboxGroup from 'antd/lib/checkbox/Group'
import { isEqual, isNil, reduce } from 'lodash-es'
import React, { FunctionComponent, useState } from 'react'
import { useUpdateEffect } from 'react-use'
import styled from 'styled-components'
import ExclusionCollectionFilter from '@/components/search/filters/common/exclusion-collection-filter'
import {
  ExcludeType,
  useExclusionFilter,
} from '@/components/search/filters/hooks/use-exclusion-filter'
import { Condition } from '@/types/api/search'

interface ExclusionFilterProps {
  filterValues: Condition
  onValuesChange: (changedValues: Condition) => void
}

const ExclusionFilterContent: FunctionComponent<ExclusionFilterProps> = ({
  filterValues,
  onValuesChange,
}) => {
  const filterValuesRef = React.useRef<Condition>(filterValues)
  const { excludeOptions, allExcludeOptionsKeys } = useExclusionFilter()
  const [excludedOptions, setExcludedOptions] = useState<ExcludeType[]>(() =>
    allExcludeOptionsKeys.filter((key) => !isNil(filterValues[key])),
  )
  const [exclusiveList, setExclusiveList] = useState<
    Condition['exclude_kol_collection_folder_ids']
  >(filterValues.exclude_kol_collection_folder_ids)

  const handleExcludeOptionChange = (
    value: ExcludeType,
    checked: boolean,
  ): void => {
    const values = checked
      ? [...excludedOptions, value]
      : excludedOptions.filter((v) => v !== value)

    setExcludedOptions(values)
  }

  useUpdateEffect(() => {
    if (!isEqual(filterValuesRef.current, filterValues)) {
      filterValuesRef.current = filterValues
      setExcludedOptions(
        allExcludeOptionsKeys.filter((key) => !isNil(filterValues[key])),
      )
    }
  }, [filterValues, excludedOptions])

  useUpdateEffect(() => {
    const excludedOptionsParams = reduce(
      allExcludeOptionsKeys,
      (result, key) => {
        result[key] = excludedOptions.includes(key) ? true : undefined
        return result
      },
      {},
    )

    onValuesChange({
      ...excludedOptionsParams,
      exclude_kol_collection_folder_ids: exclusiveList,
    })
  }, [excludedOptions, exclusiveList])

  const handleOnExcludeCollectionListChange = (
    list: Condition['exclude_kol_collection_folder_ids'],
  ): void => setExclusiveList(list)

  return (
    <SelectWrapper>
      <CheckboxGroup value={excludedOptions}>
        {excludeOptions.map(({ value, label }) => (
          <CheckboxItem key={value}>
            <Checkbox
              value={value}
              onChange={(e: CheckboxChangeEvent): void =>
                handleExcludeOptionChange(value, e.target.checked)
              }
            >
              {label}
            </Checkbox>
            {value === ExcludeType.FilterCollection &&
              excludedOptions.includes(ExcludeType.FilterCollection) && (
                <ExclusionCollectionFilter
                  initialListValue={exclusiveList}
                  onExcludeCollectionListChange={
                    handleOnExcludeCollectionListChange
                  }
                />
              )}
          </CheckboxItem>
        ))}
      </CheckboxGroup>
    </SelectWrapper>
  )
}

const SelectWrapper = styled.div`
  padding: 0 4px;

  .ant-checkbox-group {
    display: flex;
    flex-direction: column;
    align-content: space-between;
  }
`

const CheckboxItem = styled.div`
  margin-bottom: 16px;
  width: 100%;
`

export default ExclusionFilterContent
