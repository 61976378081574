import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { KolSchemaModel } from '@/types/schema/kol-schema'
import type { AddCampaignInfluencersSuccessProperties } from '@/utils/ampli'

type Kol = Pick<KolSchemaModel, 'id' | 'name' | 'potential'>

type AddKolsToCampaignModalState =
  | {
      open: true
      from?: AddCampaignInfluencersSuccessProperties['from']
      isClickedFromDrawer?: boolean
      kols: [Kol, ...Kol[]]
    }
  | {
      open: false
      from?: never
      isClickedFromDrawer?: never
      kols?: never
    }

const initialState: AddKolsToCampaignModalState = {
  open: false,
}

const addKolsToCampaignModalSlice = createSlice({
  name: 'addKolsToCampaignModal',
  initialState: (): AddKolsToCampaignModalState => initialState,
  reducers: {
    setOpen: (_state, action: PayloadAction<AddKolsToCampaignModalState>) => {
      return action.payload
    },
  },
})

export const { setOpen } = addKolsToCampaignModalSlice.actions
export const addKolsToCampaignModalReducer = addKolsToCampaignModalSlice.reducer
