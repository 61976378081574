import { EnhancerOptions } from '@redux-devtools/extension'
import { configureStore } from '@reduxjs/toolkit'
import { UnknownAction, combineReducers } from 'redux'
import { createEpicMiddleware } from 'redux-observable'
import { api } from '@/api/api'
import { googleAnalyticsAdminApi } from '@/api/google-analytics-admin-api'
import { googleApi } from '@/api/google-api'
import { k6api } from '@/api/k6-api'
import { metaApi } from '@/api/meta-api'
import { youTubeDataAPI } from '@/api/youtube-data-api'
import ZeusEnvironment from '@/constants/zeus-environment'
import { addKolsToCampaignModalReducer } from '@/store/add-kols-to-campaign-modal'
import { addKolsToReportModalReducer } from '@/store/add-kols-to-report-modal'
import campaignReducer from '@/store/campaign'
import campaignCaseReducer from '@/store/campaign-case'
import campaignReportReducer from '@/store/campaign-report'
import chatroomReducer from '@/store/chatroom'
import contractReducer from '@/store/contract'
import createWorkspaceReducer from '@/store/create-workspace'
import epics from '@/store/epics'
import isJapanReducer from '@/store/is-japan'
import kolCooperationDetailDrawerReducer from '@/store/kol-cooperation-detail-drawer'
import kolDataReducer from '@/store/kol-detail'
import meReducer from '@/store/me'
import radarCampaignReducer from '@/store/radar-campaign'
import routeReducer from '@/store/route'
import searchReducer from '@/store/search'
import * as irm from '@/store/state.irm'
import * as modal from '@/store/state.modal'
import * as report from '@/store/state.report'
import socialAuthReducer from '@/store/state.socialAuth'
import tourReducer from '@/store/tour'
import unauthorizedReducer from '@/store/unauthorized'
import userPlanReducer from '@/store/user-plan'
import { isServer } from '@/utils/is-server'

const appReducer = combineReducers({
  radarCampaign: radarCampaignReducer,
  campaign: campaignReducer,
  chatroom: chatroomReducer,
  campaignCase: campaignCaseReducer,
  campaignReport: campaignReportReducer,
  createWorkspace: createWorkspaceReducer,
  contract: contractReducer,
  irm: irm.reducer,
  kolData: kolDataReducer,
  me: meReducer,
  modal: modal.reducer,
  report: report.reducer,
  isJapan: isJapanReducer,
  userPlan: userPlanReducer,
  socialAuth: socialAuthReducer,
  search: searchReducer,
  unauthorized: unauthorizedReducer,
  tour: tourReducer,
  kolCooperationDetailDrawer: kolCooperationDetailDrawerReducer,
  addKolsToCampaignModal: addKolsToCampaignModalReducer,
  addKolsToReportModal: addKolsToReportModalReducer,
  route: routeReducer,
  [api.reducerPath]: api.reducer,
  [k6api.reducerPath]: k6api.reducer,
  [metaApi.reducerPath]: metaApi.reducer,
  [googleApi.reducerPath]: googleApi.reducer,
  [googleAnalyticsAdminApi.reducerPath]: googleAnalyticsAdminApi.reducer,
  [youTubeDataAPI.reducerPath]: youTubeDataAPI.reducer,
})

const rootReducer = (state, action): ReturnType<typeof appReducer> => {
  if (action.type === 'me/logout') {
    state = undefined
  }

  return appReducer(state, action)
}

export type RootState = ReturnType<typeof appReducer>

const epicMiddleware = createEpicMiddleware<
  UnknownAction,
  UnknownAction,
  RootState
>()
const devTools: EnhancerOptions | boolean =
  process.env.NEXT_PUBLIC_ZEUS_ENVIRONMENT === ZeusEnvironment.Production
    ? false
    : { trace: true, traceLimit: 25 }

const storeInstance = configureStore({
  devTools,
  preloadedState: {},
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([
      epicMiddleware,
      metaApi.middleware,
      googleApi.middleware,
      api.middleware,
      k6api.middleware,
      googleAnalyticsAdminApi.middleware,
      youTubeDataAPI.middleware,
    ]),
})

export type AppStore = typeof storeInstance
let store: AppStore

export const initStore = (): AppStore => {
  if (isServer()) {
    return storeInstance
  }

  if (store === undefined) {
    store = storeInstance
    epicMiddleware.run(epics)
  }

  return store
}

export type AppDispatch = typeof store.dispatch
