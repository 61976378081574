import { api, BasicRequest, WorkspacesAPITagType } from '@/api/api'
import { ReportKol, ReportKolsResponse } from '@/api/kols-api'
import defaultStringifyRequest from '@/constants/default-stringify-request'
import {
  DEMO_COMPETITIVE_ANALYSIS_ID,
  demoCompetitiveBrandAnalysisKolListCharts,
  demoCompetitiveBrandAnalysisKolListEffectiveness,
  demoCompetitiveBrandAnalysisKolListMetricCharts,
  demoCompetitiveBrandKolStatisticListCharts,
  demoCompetitiveBrandKolStatisticListTable,
  demoCompetitiveKeywordAnalysis,
  demoCompetitiveKeywordAnalysisKeywordList,
  demoCompetitiveKeywordEffectivenessTrendList,
  demoCompetitiveKeywordKolCustomizedTagsNameChartData,
  demoCompetitiveKeywordKolFollowerChartData,
  demoCompetitiveKeywordPostKolList,
  demoCompetitiveKeywordSimilarKolList,
  demoCompetitiveKeywordStatisticList,
  demoCompetitiveKeywordTopTenPostList,
  demoCompetitiveMentionAnalysis,
  demoCompetitiveMentionAnalysisKolAnalyzeList,
  demoCompetitiveMentionedKolFollowerChartData,
  demoCompetitiveMentionedKolList,
  demoCompetitiveMentionedTopTenPostList,
  demoCompetitiveMentionEffectivenessTrendChartData,
  demoCompetitiveMentionKolPropertyChartData,
  demoCompetitiveMentionPostKolList,
  demoCompetitiveMentionSimilarKolList,
  demoCrawlerStatus,
  demoStaticHashtags,
  getDemoCompetitiveKeywordEfficientTrendDetail,
  getDemoCompetitiveMentionedEfficientTrendDetail,
} from '@/constants/demo-competitive'
import {
  BasicCompetitiveAnalysisRequest,
  CreateCompetitiveKeywordAnalysisRequest,
  CreateCompetitiveMentionAnalysisRequest,
  ExportCompetitiveKeywordPostKolListRequest,
  ExportCompetitiveMentionPostKolListRequest,
  FetchCompetitiveBrandAnalysisKolListChartsRequest,
  FetchCompetitiveBrandAnalysisKolListEffectivenessTrendRequest,
  FetchCompetitiveBrandAnalysisKolListMetricChartsRequest,
  FetchCompetitiveKeywordAnalysisListRequest,
  FetchCompetitiveKeywordAnalysisStaticListRequest,
  FetchCompetitiveKeywordEffectivenessTrendChartDataRequest,
  FetchCompetitiveKeywordEffectivenessTrendChartDetailRequest,
  FetchCompetitiveKeywordHashtagListRequest,
  FetchCompetitiveKeywordKolCustomizedTagsNameChartDataRequest,
  FetchCompetitiveKeywordKolFollowerChartDataRequest,
  FetchCompetitiveKeywordPostKolListRequest,
  FetchCompetitiveKeywordSimilarKolListRequest,
  FetchCompetitiveKeywordTopTenPostListRequest,
  FetchCompetitiveKolStatisticListChartsRequest,
  FetchCompetitiveKolStatisticListRequest,
  FetchCompetitiveMentionAnalysisKolAnalyzeListRequest,
  FetchCompetitiveMentionAnalysisKolListRequest,
  FetchCompetitiveMentionAnalysisListRequest,
  FetchCompetitiveMentionEffectivenessTrendChartDataRequest,
  FetchCompetitiveMentionEffectivenessTrendChartDetailRequest,
  FetchCompetitiveMentionKolFollowerChartDataRequest,
  FetchCompetitiveMentionKolPropertyChartDataRequest,
  FetchCompetitiveMentionPostKolListRequest,
  FetchCompetitiveMentionSimilarKolListRequest,
  FetchCompetitiveMentionTopTenPostListRequest,
  FetchCompetitiveStaticHashTagListRequest,
  ModifyCompetitiveKeywordAnalysisRequest,
  ModifyCompetitiveMentionAnalysis,
  ReportCompetitiveKolsRequest,
  UpdateCompetitiveAnalysisKolListRequest,
  UpdateCompetitiveKeywordAnalysisKeywordListRequest,
} from '@/types/api/competitor-analyses'
import {
  CompetitiveBrandAnalysisKolListChartsResponse,
  CompetitiveBrandAnalysisKolListEffectivenessResponse,
  CompetitiveBrandAnalysisKolListMetricChartsResponse,
  CompetitiveBrandAnalysisStaticHashTagListResponse,
  CompetitiveKolStatisticListCharts,
  competitiveKolStatisticListChartsResponseSchema,
  CompetitiveKolStatisticListResponse,
  competitiveKolStatisticListResponseSchema,
} from '@/types/schema/api/competitive-brand-analyses'
import {
  CompetitiveMentionAnalysesCreatorList,
  competitiveMentionAnalysesCreatorListSchema,
  CompetitiveMentionAnalysisKolAnalyzeListResponse,
  competitiveMentionAnalysisKolAnalyzeListResponseSchema,
  CompetitiveMentionAnalysisList,
  competitiveMentionAnalysisListSchema,
  CompetitiveMentionStaticHashTagListResponse,
  CompetitiveMentionTopTenPostList,
  competitiveMentionTopTenPostListSchema,
  FetchCompetitiveKeywordSimilarKolListResponse,
  fetchCompetitiveKeywordSimilarKolListResponseSchema,
  FetchCompetitiveMentionEffectivenessTrendChartDataResponse,
  fetchCompetitiveMentionEffectivenessTrendChartDataResponseSchema,
  FetchCompetitiveMentionKolFollowerChartDataResponse,
  fetchCompetitiveMentionKolFollowerChartDataResponseSchema,
  FetchCompetitiveMentionKolPropertyChartDataResponse,
  fetchCompetitiveMentionKolPropertyChartDataResponseSchema,
  FetchCompetitiveMentionPostKolListResponse,
  fetchCompetitiveMentionPostKolListResponseSchema,
  FetchCompetitiveMentionSimilarKolListResponse,
  fetchCompetitiveMentionSimilarKolListResponseSchema,
} from '@/types/schema/api/competitive-mention-analyses'
import {
  CompetitiveAnalysisKolList,
  competitiveAnalysisKolListSchema,
} from '@/types/schema/competitive-analyses'
import {
  CompetitiveAnalysisCrawlerStatus,
  competitiveAnalysisCrawlerStatusSchema,
  competitiveKolListChartsSchema,
  competitiveKolListEffectiveChartSchema,
  competitiveKolListMetricChartsSchema,
  competitiveStaticHashTagsSchema,
} from '@/types/schema/competitive-analyses/competitive-brand-analyses'
import {
  CompetitiveKeywordAnalysesCreatorList,
  competitiveKeywordAnalysesCreatorListSchema,
  CompetitiveKeywordAnalysis,
  CompetitiveKeywordAnalysisHashtagList,
  CompetitiveKeywordAnalysisKeywordList,
  competitiveKeywordAnalysisKeywordListSchema,
  CompetitiveKeywordAnalysisList,
  competitiveKeywordAnalysisListSchema,
  competitiveKeywordAnalysisSchema,
  competitiveKeywordAnalysisStatisticList,
  CompetitiveKeywordAnalysisStatisticList,
  CompetitiveKeywordEffectivenessTrendChartDetail,
  competitiveKeywordEffectivenessTrendChartDetailSchema,
  competitiveKeywordEffectivenessTrendList,
  CompetitiveKeywordEffectivenessTrendList,
  CompetitiveKeywordTopTenPostList,
  competitiveKeywordTopTenPostListSchema,
  CreateCompetitiveKeywordAnalysisResponse,
  createCompetitiveKeywordAnalysisResponseSchema,
  FetchCompetitiveKeywordKolCustomizedTagsNameChartDataResponse,
  fetchCompetitiveKeywordKolCustomizedTagsNameChartDataResponseSchema,
  FetchCompetitiveKeywordKolFollowerChartDataResponse,
  fetchCompetitiveKeywordKolFollowerChartDataResponseSchema,
  FetchCompetitiveKeywordPostKolListResponse,
  fetchCompetitiveKeywordPostKolListResponseSchema,
} from '@/types/schema/competitive-analyses/competitive-keyword-analyses'
import {
  CompetitiveMentionAnalysis,
  competitiveMentionAnalysisSchema,
  CompetitiveMentionEffectivenessTrendChartDetail,
  competitiveMentionEffectivenessTrendChartDetailSchema,
  CreateCompetitiveMentionAnalysisResponse,
  createCompetitiveMentionAnalysisResponseSchema,
} from '@/types/schema/competitive-analyses/competitive-mention-analyses'
import removeNil from '@/utils/remove-nil'

export const competitiveAnalysesApi = api.injectEndpoints({
  endpoints: (build) => ({
    fetchCompetitiveKeywordTopTenPostList: build.query<
      CompetitiveKeywordTopTenPostList,
      FetchCompetitiveKeywordTopTenPostListRequest
    >({
      queryFn: async (
        { workspaceId, id, ...params },
        _queryApi,
        _extraOptions,
        baseQuery,
      ) => {
        if (id === DEMO_COMPETITIVE_ANALYSIS_ID) {
          return { data: demoCompetitiveKeywordTopTenPostList }
        }

        return (await baseQuery({
          url: `/workspaces/${workspaceId}/competitive-mention-keyword-analyses/${id}/analyze/top10`,
          params: { ...params, ...defaultStringifyRequest },
        })) as {
          data: CompetitiveKeywordTopTenPostList
        }
      },
      extraOptions: {
        dataSchema: competitiveKeywordTopTenPostListSchema,
      },
      providesTags: [WorkspacesAPITagType.PostCollectionPost],
    }),
    fetchCompetitiveMentionTopTenPostList: build.query<
      CompetitiveMentionTopTenPostList,
      FetchCompetitiveMentionTopTenPostListRequest
    >({
      queryFn: async (
        { workspaceId, id, ...params },
        _queryApi,
        _extraOptions,
        baseQuery,
      ) => {
        if (id === DEMO_COMPETITIVE_ANALYSIS_ID) {
          return { data: demoCompetitiveMentionedTopTenPostList }
        }

        return (await baseQuery({
          url: `/workspaces/${workspaceId}/competitive-mention-analyses/${id}/analyze/top10`,
          params: { ...params, ...defaultStringifyRequest },
        })) as {
          data: CompetitiveMentionTopTenPostList
        }
      },
      extraOptions: {
        dataSchema: competitiveMentionTopTenPostListSchema,
      },
      providesTags: [WorkspacesAPITagType.PostCollectionPost],
    }),
    fetchCompetitiveKeywordHashtagList: build.query<
      CompetitiveKeywordAnalysisHashtagList,
      FetchCompetitiveKeywordHashtagListRequest
    >({
      queryFn: async (
        { id, workspaceId, ...params },
        _queryApi,
        _extraOptions,
        baseQuery,
      ) => {
        if (id === DEMO_COMPETITIVE_ANALYSIS_ID) {
          return {
            data: demoStaticHashtags,
          }
        }

        return (await baseQuery({
          url: `/workspaces/${workspaceId}/competitive-mention-keyword-analyses/${id}/analyze/hashtags`,
          params: { ...removeNil(params), ...defaultStringifyRequest },
        })) as { data: CompetitiveKeywordAnalysisHashtagList }
      },
      extraOptions: {
        dataSchema: competitiveStaticHashTagsSchema,
      },
      providesTags: [
        WorkspacesAPITagType.CompetitiveKeywordAnalysisHashtagList,
      ],
    }),
    fetchCompetitiveKeywordPostKolList: build.query<
      FetchCompetitiveKeywordPostKolListResponse,
      FetchCompetitiveKeywordPostKolListRequest
    >({
      queryFn: async (
        { workspaceId, id, ...params },
        _queryApi,
        _extraOptions,
        baseQuery,
      ) => {
        if (id === DEMO_COMPETITIVE_ANALYSIS_ID) {
          return { data: demoCompetitiveKeywordPostKolList }
        }

        return (await baseQuery({
          url: `/workspaces/${workspaceId}/competitive-mention-keyword-analyses/${id}/analyze/competitive-mention-kols`,
          params: { ...removeNil(params), ...defaultStringifyRequest },
        })) as { data: FetchCompetitiveKeywordPostKolListResponse }
      },
      extraOptions: {
        dataSchema: fetchCompetitiveKeywordPostKolListResponseSchema,
      },
    }),
    exportCompetitiveKeywordPostKolList: build.query<
      ArrayBuffer,
      ExportCompetitiveKeywordPostKolListRequest
    >({
      query: ({ id, workspaceId, ...params }) => {
        return {
          url: `/workspaces/${workspaceId}/competitive-mention-keyword-analyses/${id}/analyze/competitive-mention-kols/export`,
          params: { ...params, stringify: true, array_format: 'bracket' },
          responseHandler: (response): Promise<ArrayBuffer> =>
            response.arrayBuffer(),
        }
      },
    }),
    exportCompetitiveMentionPostKolList: build.query<
      ArrayBuffer,
      ExportCompetitiveMentionPostKolListRequest
    >({
      query: ({ id, workspaceId, ...params }) => {
        return {
          url: `/workspaces/${workspaceId}/competitive-mention-analyses/${id}/analyze/competitive-mention-kols/export`,
          params: { ...params, stringify: true, array_format: 'bracket' },
          responseHandler: (response): Promise<ArrayBuffer> =>
            response.arrayBuffer(),
        }
      },
    }),
    fetchCompetitiveKeywordKolCustomizedTagsNameChartData: build.query<
      FetchCompetitiveKeywordKolCustomizedTagsNameChartDataResponse,
      FetchCompetitiveKeywordKolCustomizedTagsNameChartDataRequest
    >({
      queryFn: async (
        { workspaceId, id, ...params },
        _queryApi,
        _extraOptions,
        baseQuery,
      ) => {
        if (id === DEMO_COMPETITIVE_ANALYSIS_ID) {
          return {
            data: demoCompetitiveKeywordKolCustomizedTagsNameChartData,
          }
        }

        return (await baseQuery({
          url: `/workspaces/${workspaceId}/competitive-mention-keyword-analyses/${id}/analyze/mention-keyword-property-charts`,
          params: { ...params, ...defaultStringifyRequest },
        })) as {
          data: FetchCompetitiveKeywordKolCustomizedTagsNameChartDataResponse
        }
      },
      extraOptions: {
        dataSchema:
          fetchCompetitiveKeywordKolCustomizedTagsNameChartDataResponseSchema,
      },
      providesTags: [
        WorkspacesAPITagType.CompetitiveKeywordKolCustomizedTagsNameChartData,
      ],
    }),
    fetchCompetitiveMentionKolFollowerChartData: build.query<
      FetchCompetitiveMentionKolFollowerChartDataResponse,
      FetchCompetitiveMentionKolFollowerChartDataRequest
    >({
      queryFn: async (
        { workspaceId, id, ...params },
        _queryApi,
        _extraOptions,
        baseQuery,
      ) => {
        if (id === DEMO_COMPETITIVE_ANALYSIS_ID) {
          return { data: demoCompetitiveMentionedKolFollowerChartData }
        }

        return (await baseQuery({
          url: `/workspaces/${workspaceId}/competitive-mention-analyses/${id}/analyze/mention-kol-follower-charts`,
          params: { ...params, ...defaultStringifyRequest },
        })) as {
          data: FetchCompetitiveMentionKolFollowerChartDataResponse
        }
      },
      extraOptions: {
        dataSchema: fetchCompetitiveMentionKolFollowerChartDataResponseSchema,
      },
    }),
    fetchCompetitiveKeywordAnalysisList: build.query<
      CompetitiveKeywordAnalysisList,
      FetchCompetitiveKeywordAnalysisListRequest
    >({
      query: ({ workspaceId, ...params }) => {
        return {
          url: `/workspaces/${workspaceId}/competitive-mention-keyword-analyses`,
          params: { ...params, ...defaultStringifyRequest },
        }
      },
      extraOptions: {
        dataSchema: competitiveKeywordAnalysisListSchema,
      },
      providesTags: [WorkspacesAPITagType.CompetitiveKeywordAnalysisList],
    }),
    fetchCompetitiveKeywordAnalysisKeywordList: build.query<
      CompetitiveKeywordAnalysisKeywordList,
      Omit<BasicCompetitiveAnalysisRequest, 'country_code'>
    >({
      queryFn: async (
        { workspaceId, id },
        _queryApi,
        _extraOptions,
        baseQuery,
      ) => {
        if (id === DEMO_COMPETITIVE_ANALYSIS_ID) {
          return { data: demoCompetitiveKeywordAnalysisKeywordList }
        }
        return (await baseQuery({
          url: `/workspaces/${workspaceId}/competitive-mention-keyword-analyses/${id}/keywords`,
        })) as { data: CompetitiveKeywordAnalysisKeywordList }
      },
      extraOptions: {
        dataSchema: competitiveKeywordAnalysisKeywordListSchema,
      },
      providesTags: [
        WorkspacesAPITagType.CompetitiveKeywordAnalysisKeywordList,
      ],
    }),
    createCompetitiveKeywordAnalysis: build.mutation<
      CreateCompetitiveKeywordAnalysisResponse,
      CreateCompetitiveKeywordAnalysisRequest
    >({
      query: ({ workspaceId, ...body }) => {
        return {
          url: `/workspaces/${workspaceId}/competitive-mention-keyword-analyses`,
          method: 'POST',
          body,
        }
      },
      extraOptions: {
        dataSchema: createCompetitiveKeywordAnalysisResponseSchema,
      },
      invalidatesTags: [
        WorkspacesAPITagType.CompetitiveKeywordAnalysisList,
        WorkspacesAPITagType.CompetitiveKeywordAnalysesCreatorList,
      ],
    }),
    deleteCompetitiveKeywordAnalysis: build.mutation<
      void,
      Omit<BasicCompetitiveAnalysisRequest, 'country_code'>
    >({
      query: ({ workspaceId, id }) => {
        return {
          url: `/workspaces/${workspaceId}/competitive-mention-keyword-analyses/${id}`,
          method: 'DELETE',
        }
      },
      invalidatesTags: [WorkspacesAPITagType.CompetitiveKeywordAnalysisList],
    }),
    modifyCompetitiveKeywordAnalysis: build.mutation<
      void,
      ModifyCompetitiveKeywordAnalysisRequest
    >({
      query: ({ workspaceId, id, ...body }) => {
        return {
          url: `/workspaces/${workspaceId}/competitive-mention-keyword-analyses/${id}`,
          method: 'PATCH',
          body,
        }
      },
      invalidatesTags: [
        WorkspacesAPITagType.CompetitiveKeywordAnalysis,
        WorkspacesAPITagType.CompetitiveKeywordAnalysisList,
      ],
    }),
    updateCompetitiveKeywordAnalysisKeywordList: build.mutation<
      void,
      UpdateCompetitiveKeywordAnalysisKeywordListRequest
    >({
      query: ({ workspaceId, id, ...body }) => {
        return {
          url: `/workspaces/${workspaceId}/competitive-mention-keyword-analyses/${id}/keywords`,
          method: 'PUT',
          body,
        }
      },
      invalidatesTags: [
        WorkspacesAPITagType.CompetitiveKeywordAnalysis,
        WorkspacesAPITagType.CompetitiveKeywordAnalysisList,
        WorkspacesAPITagType.CompetitiveKeywordAnalysisKeywordList,
        WorkspacesAPITagType.CompetitiveKeywordAnalysisStatisticList,
        WorkspacesAPITagType.CompetitiveKeywordStatisticListCharts,
      ],
    }),
    fetchCompetitiveKeywordAnalysis: build.query<
      CompetitiveKeywordAnalysis,
      Omit<BasicCompetitiveAnalysisRequest, 'country_code'>
    >({
      queryFn: async (
        { workspaceId, id },
        _queryApi,
        _extraOptions,
        baseQuery,
      ) => {
        if (id === DEMO_COMPETITIVE_ANALYSIS_ID) {
          return { data: demoCompetitiveKeywordAnalysis }
        }

        return (await baseQuery({
          url: `/workspaces/${workspaceId}/competitive-mention-keyword-analyses/${id}`,
        })) as { data: CompetitiveKeywordAnalysis }
      },
      extraOptions: {
        dataSchema: competitiveKeywordAnalysisSchema,
      },
      providesTags: [WorkspacesAPITagType.CompetitiveKeywordAnalysis],
    }),
    fetchCompetitiveKeywordStatisticList: build.query<
      CompetitiveKeywordAnalysisStatisticList,
      FetchCompetitiveKeywordAnalysisStaticListRequest
    >({
      queryFn: async (
        { id, workspaceId, ...params },
        _queryApi,
        _extraOptions,
        baseQuery,
      ) => {
        if (id === DEMO_COMPETITIVE_ANALYSIS_ID) {
          return { data: demoCompetitiveKeywordStatisticList }
        }

        return (await baseQuery({
          url: `/workspaces/${workspaceId}/competitive-mention-keyword-analyses/${id}/analyze/keywords`,
          params,
        })) as { data: CompetitiveKeywordAnalysisStatisticList }
      },
      extraOptions: {
        dataSchema: competitiveKeywordAnalysisStatisticList,
      },
      providesTags: [
        WorkspacesAPITagType.CompetitiveKeywordAnalysisStatisticList,
      ],
    }),
    fetchCompetitiveKeywordEffectivenessTrendChartData: build.query<
      CompetitiveKeywordEffectivenessTrendList,
      FetchCompetitiveKeywordEffectivenessTrendChartDataRequest
    >({
      queryFn: async (
        { workspaceId, id, ...params },
        _queryApi,
        _extraOptions,
        baseQuery,
      ) => {
        if (id === DEMO_COMPETITIVE_ANALYSIS_ID) {
          return {
            data: demoCompetitiveKeywordEffectivenessTrendList,
          }
        }

        return (await baseQuery({
          url: `/workspaces/${workspaceId}/competitive-mention-keyword-analyses/${id}/analyze/effectiveness-trend`,
          params: { ...params, ...defaultStringifyRequest },
        })) as {
          data: CompetitiveKeywordEffectivenessTrendList
        }
      },
      extraOptions: {
        dataSchema: competitiveKeywordEffectivenessTrendList,
      },
    }),
    fetchCompetitiveKeywordKolFollowerChartData: build.query<
      FetchCompetitiveKeywordKolFollowerChartDataResponse,
      FetchCompetitiveKeywordKolFollowerChartDataRequest
    >({
      queryFn: async (
        { workspaceId, id, ...params },
        _queryApi,
        _extraOptions,
        baseQuery,
      ) => {
        if (id === DEMO_COMPETITIVE_ANALYSIS_ID) {
          return { data: demoCompetitiveKeywordKolFollowerChartData }
        }

        return (await baseQuery({
          url: `/workspaces/${workspaceId}/competitive-mention-keyword-analyses/${id}/analyze/mention-kol-follower-charts`,
          params: { ...params, ...defaultStringifyRequest },
        })) as {
          data: FetchCompetitiveKeywordKolFollowerChartDataResponse
        }
      },
      extraOptions: {
        dataSchema: fetchCompetitiveKeywordKolFollowerChartDataResponseSchema,
      },
    }),
    fetchCompetitiveKeywordEffectivenessTrendChartDetail: build.query<
      CompetitiveKeywordEffectivenessTrendChartDetail,
      FetchCompetitiveKeywordEffectivenessTrendChartDetailRequest
    >({
      queryFn: async (
        { workspaceId, id, ...params },
        _queryApi,
        _extraOptions,
        baseQuery,
      ) => {
        if (id === DEMO_COMPETITIVE_ANALYSIS_ID) {
          const demoCompetitiveMentionedEfficientTrendDetail =
            getDemoCompetitiveKeywordEfficientTrendDetail(
              params.keyword_id,
              params.time,
            )

          return { data: demoCompetitiveMentionedEfficientTrendDetail }
        }

        return (await baseQuery({
          url: `/workspaces/${workspaceId}/competitive-mention-keyword-analyses/${id}/analyze/effectiveness-trend/detail`,
          params: removeNil(params),
        })) as {
          data: CompetitiveKeywordEffectivenessTrendChartDetail
        }
      },
      extraOptions: {
        dataSchema: competitiveKeywordEffectivenessTrendChartDetailSchema,
      },
    }),
    fetchCompetitiveKeywordAnalysesCreatorList: build.query<
      CompetitiveKeywordAnalysesCreatorList,
      BasicRequest
    >({
      query: ({ workspaceId }) => {
        return `/workspaces/${workspaceId}/competitive-mention-keyword-analyses/creators`
      },
      extraOptions: {
        dataSchema: competitiveKeywordAnalysesCreatorListSchema,
      },
      providesTags: [
        WorkspacesAPITagType.CompetitiveKeywordAnalysesCreatorList,
      ],
    }),
    fetchCompetitiveKeywordSimilarKolList: build.query<
      FetchCompetitiveKeywordSimilarKolListResponse,
      FetchCompetitiveKeywordSimilarKolListRequest
    >({
      queryFn: async (
        { workspaceId, id, ...params },
        _queryApi,
        _extraOptions,
        baseQuery,
      ) => {
        if (id === DEMO_COMPETITIVE_ANALYSIS_ID) {
          return { data: demoCompetitiveKeywordSimilarKolList }
        }
        return (await baseQuery({
          url: `/workspaces/${workspaceId}/competitive-mention-keyword-analyses/${id}/analyze/competitive-keyword-similar-kols`,
          params: { ...params, ...defaultStringifyRequest },
        })) as { data: FetchCompetitiveKeywordSimilarKolListResponse }
      },
      extraOptions: {
        dataSchema: fetchCompetitiveKeywordSimilarKolListResponseSchema,
      },
    }),
    fetchCompetitiveBrandAnalysisCrawlerStatus: build.query<
      CompetitiveAnalysisCrawlerStatus,
      Omit<BasicCompetitiveAnalysisRequest, 'country_code'>
    >({
      queryFn: async (
        { workspaceId, id },
        _queryApi,
        _extraOptions,
        baseQuery,
      ) => {
        if (id === DEMO_COMPETITIVE_ANALYSIS_ID) {
          return { data: demoCrawlerStatus }
        }
        return (await baseQuery({
          url: `/workspaces/${workspaceId}/competitive-brand-analyses/${id}/crawler-status`,
        })) as { data: CompetitiveAnalysisCrawlerStatus }
      },
      extraOptions: {
        dataSchema: competitiveAnalysisCrawlerStatusSchema,
      },
      providesTags: [WorkspacesAPITagType.CompetitiveAnalysisCrawlerStatus],
    }),
    triggerCompetitiveBrandAnalysisCrawler: build.mutation<
      void,
      Omit<BasicCompetitiveAnalysisRequest, 'country_code'>
    >({
      query: ({ workspaceId, id }) => {
        return {
          url: `/workspaces/${workspaceId}/competitive-brand-analyses/${id}/trigger-crawler`,
          method: 'POST',
        }
      },
      invalidatesTags: [WorkspacesAPITagType.CompetitiveAnalysisCrawlerStatus],
    }),
    fetchCompetitiveKolStatisticList: build.query<
      CompetitiveKolStatisticListResponse,
      FetchCompetitiveKolStatisticListRequest
    >({
      queryFn: async (
        { id, workspaceId, ...params },
        _queryApi,
        _extraOptions,
        baseQuery,
      ) => {
        if (id === DEMO_COMPETITIVE_ANALYSIS_ID) {
          return {
            data: demoCompetitiveBrandKolStatisticListTable,
          }
        }

        return (await baseQuery({
          url: `/workspaces/${workspaceId}/competitive-brand-analyses/${id}/competitive-kols/statistics`,
          params: removeNil(params),
        })) as { data: CompetitiveKolStatisticListResponse }
      },
      extraOptions: {
        dataSchema: competitiveKolStatisticListResponseSchema,
      },
      providesTags: [WorkspacesAPITagType.CompetitiveBrandKolStatisticList],
    }),
    fetchCompetitiveKolStatisticListCharts: build.query<
      CompetitiveKolStatisticListCharts,
      FetchCompetitiveKolStatisticListChartsRequest
    >({
      queryFn: async (
        { id, workspaceId, ...params },
        _queryApi,
        _extraOptions,
        baseQuery,
      ) => {
        if (id === DEMO_COMPETITIVE_ANALYSIS_ID) {
          return {
            data: demoCompetitiveBrandKolStatisticListCharts,
          }
        }
        return (await baseQuery({
          url: `/workspaces/${workspaceId}/competitive-brand-analyses/${id}/competitive-kols/statistic-charts`,
          params: removeNil(params),
        })) as { data: CompetitiveKolStatisticListCharts }
      },
      extraOptions: {
        dataSchema: competitiveKolStatisticListChartsResponseSchema,
      },
      providesTags: [
        WorkspacesAPITagType.CompetitiveBrandKolStatisticListCharts,
      ],
    }),
    fetchCompetitiveBrandAnalysisEffectivenessTrend: build.query<
      CompetitiveBrandAnalysisKolListEffectivenessResponse,
      FetchCompetitiveBrandAnalysisKolListEffectivenessTrendRequest
    >({
      queryFn: async (
        { id, workspaceId, ...params },
        _queryApi,
        _extraOptions,
        baseQuery,
      ) => {
        if (id === DEMO_COMPETITIVE_ANALYSIS_ID) {
          return {
            data: demoCompetitiveBrandAnalysisKolListEffectiveness,
          }
        }

        return (await baseQuery({
          url: `/workspaces/${workspaceId}/competitive-brand-analyses/${id}/analyze/effectiveness-trend`,
          params: { ...params, ...defaultStringifyRequest },
        })) as { data: CompetitiveBrandAnalysisKolListEffectivenessResponse }
      },
      extraOptions: {
        dataSchema: competitiveKolListEffectiveChartSchema,
      },
    }),
    fetchCompetitiveBrandAnalysisKolListCharts: build.query<
      CompetitiveBrandAnalysisKolListChartsResponse,
      FetchCompetitiveBrandAnalysisKolListChartsRequest
    >({
      queryFn: async (
        { id, workspaceId, ...params },
        _queryApi,
        _extraOptions,
        baseQuery,
      ) => {
        if (id === DEMO_COMPETITIVE_ANALYSIS_ID) {
          return {
            data: demoCompetitiveBrandAnalysisKolListCharts,
          }
        }

        return (await baseQuery({
          url: `/workspaces/${workspaceId}/competitive-brand-analyses/${id}/competitive-kols/platform-statistic-charts`,
          params: { ...removeNil(params), ...defaultStringifyRequest },
        })) as { data: CompetitiveBrandAnalysisKolListChartsResponse }
      },
      extraOptions: {
        dataSchema: competitiveKolListChartsSchema,
      },
    }),
    fetchCompetitiveBrandAnalysisKolListMetricCharts: build.query<
      CompetitiveBrandAnalysisKolListMetricChartsResponse,
      FetchCompetitiveBrandAnalysisKolListMetricChartsRequest
    >({
      queryFn: async (
        { id, workspaceId, ...params },
        _queryApi,
        _extraOptions,
        baseQuery,
      ) => {
        if (id === DEMO_COMPETITIVE_ANALYSIS_ID) {
          return {
            data: demoCompetitiveBrandAnalysisKolListMetricCharts,
          }
        }

        return (await baseQuery({
          url: `/workspaces/${workspaceId}/competitive-brand-analyses/${id}/competitive-kols/statistic-metric-charts`,
          params: { ...removeNil(params), ...defaultStringifyRequest },
        })) as { data: CompetitiveBrandAnalysisKolListMetricChartsResponse }
      },
      extraOptions: {
        dataSchema: competitiveKolListMetricChartsSchema,
      },
    }),
    fetchCompetitiveBrandAnalysisStaticHashtags: build.query<
      CompetitiveBrandAnalysisStaticHashTagListResponse,
      Omit<FetchCompetitiveStaticHashTagListRequest, 'country_code'>
    >({
      queryFn: async (
        { id, workspaceId, ...params },
        _queryApi,
        _extraOptions,
        baseQuery,
      ) => {
        if (id === DEMO_COMPETITIVE_ANALYSIS_ID) {
          return {
            data: demoStaticHashtags,
          }
        }

        return (await baseQuery({
          url: `/workspaces/${workspaceId}/competitive-brand-analyses/${id}/analyze/hashtags`,
          params: { ...removeNil(params), ...defaultStringifyRequest },
        })) as { data: CompetitiveBrandAnalysisStaticHashTagListResponse }
      },
      extraOptions: {
        dataSchema: competitiveStaticHashTagsSchema,
      },
      providesTags: [WorkspacesAPITagType.CompetitiveBrandAnalysesHashTagList],
    }),
    fetchCompetitiveMentionAnalysisList: build.query<
      CompetitiveMentionAnalysisList,
      FetchCompetitiveMentionAnalysisListRequest
    >({
      query: ({ workspaceId, ...params }) => {
        return {
          url: `/workspaces/${workspaceId}/competitive-mention-analyses`,
          params: { ...params, stringify: true, array_format: 'bracket' },
        }
      },
      extraOptions: {
        dataSchema: competitiveMentionAnalysisListSchema,
      },
      providesTags: [WorkspacesAPITagType.CompetitiveMentionAnalysisList],
    }),
    createCompetitiveMentionAnalysis: build.mutation<
      CreateCompetitiveMentionAnalysisResponse,
      CreateCompetitiveMentionAnalysisRequest
    >({
      query: ({ workspaceId, ...body }) => {
        return {
          url: `/workspaces/${workspaceId}/competitive-mention-analyses`,
          method: 'POST',
          body,
        }
      },
      extraOptions: {
        dataSchema: createCompetitiveMentionAnalysisResponseSchema,
      },
      invalidatesTags: [
        WorkspacesAPITagType.CompetitiveMentionAnalysisList,
        WorkspacesAPITagType.CompetitiveMentionAnalysesCreatorList,
      ],
    }),
    fetchCompetitiveMentionAnalysesCreatorList: build.query<
      CompetitiveMentionAnalysesCreatorList,
      BasicRequest
    >({
      query: ({ workspaceId }) => {
        return `/workspaces/${workspaceId}/competitive-mention-analyses/creators`
      },
      extraOptions: {
        dataSchema: competitiveMentionAnalysesCreatorListSchema,
      },
      providesTags: [
        WorkspacesAPITagType.CompetitiveMentionAnalysesCreatorList,
      ],
    }),
    fetchCompetitiveMentionAnalysis: build.query<
      CompetitiveMentionAnalysis,
      Omit<BasicCompetitiveAnalysisRequest, 'country_code'>
    >({
      queryFn: async (
        { workspaceId, id },
        _queryApi,
        _extraOptions,
        baseQuery,
      ) => {
        if (id === DEMO_COMPETITIVE_ANALYSIS_ID) {
          return { data: demoCompetitiveMentionAnalysis }
        }

        return (await baseQuery({
          url: `/workspaces/${workspaceId}/competitive-mention-analyses/${id}`,
        })) as { data: CompetitiveMentionAnalysis }
      },
      extraOptions: {
        dataSchema: competitiveMentionAnalysisSchema,
      },
      providesTags: [WorkspacesAPITagType.CompetitiveMentionAnalysis],
    }),
    fetchCompetitiveMentionAnalysisKolList: build.query<
      CompetitiveAnalysisKolList,
      FetchCompetitiveMentionAnalysisKolListRequest
    >({
      queryFn: async (
        { workspaceId, id, without_official_accounts },
        _queryApi,
        _extraOptions,
        baseQuery,
      ) => {
        if (id === DEMO_COMPETITIVE_ANALYSIS_ID) {
          return { data: demoCompetitiveMentionedKolList }
        }

        return (await baseQuery({
          url: `/workspaces/${workspaceId}/competitive-mention-analyses/${id}/kols`,
          params: { without_official_accounts },
        })) as { data: CompetitiveAnalysisKolList }
      },
      extraOptions: {
        dataSchema: competitiveAnalysisKolListSchema,
      },
      providesTags: [WorkspacesAPITagType.CompetitiveMentionAnalysisKolList],
    }),
    updateCompetitiveMentionAnalysisKolList: build.mutation<
      void,
      UpdateCompetitiveAnalysisKolListRequest
    >({
      query: ({ workspaceId, id, ...body }) => {
        return {
          url: `/workspaces/${workspaceId}/competitive-mention-analyses/${id}/kols`,
          method: 'PUT',
          body,
        }
      },
      invalidatesTags: [
        WorkspacesAPITagType.CompetitiveMentionAnalysisList,
        WorkspacesAPITagType.CompetitiveMentionAnalysisKolList,
        WorkspacesAPITagType.CompetitiveMentionKolPropertyChartData,
        WorkspacesAPITagType.CompetitiveBrandKolStatisticList,
        WorkspacesAPITagType.CompetitiveBrandKolStatisticListCharts,
        WorkspacesAPITagType.KolManagement,
        WorkspacesAPITagType.KolCollectionKols,
      ],
    }),
    fetchCompetitiveMentionAnalysisKolAnalyzeList: build.query<
      CompetitiveMentionAnalysisKolAnalyzeListResponse,
      FetchCompetitiveMentionAnalysisKolAnalyzeListRequest
    >({
      queryFn: async (
        { id, workspaceId, ...params },
        _queryApi,
        _extraOptions,
        baseQuery,
      ) => {
        if (id === DEMO_COMPETITIVE_ANALYSIS_ID) {
          return { data: demoCompetitiveMentionAnalysisKolAnalyzeList }
        }

        return (await baseQuery({
          url: `/workspaces/${workspaceId}/competitive-mention-analyses/${id}/analyze/kols`,
          params: removeNil(params),
        })) as { data: CompetitiveMentionAnalysisKolAnalyzeListResponse }
      },
      extraOptions: {
        dataSchema: competitiveMentionAnalysisKolAnalyzeListResponseSchema,
      },
      providesTags: [WorkspacesAPITagType.CompetitiveMentionAnalysisKolList],
    }),
    modifyCompetitiveMentionAnalysis: build.mutation<
      void,
      ModifyCompetitiveMentionAnalysis
    >({
      query: ({ workspaceId, id, ...body }) => {
        return {
          url: `/workspaces/${workspaceId}/competitive-mention-analyses/${id}`,
          method: 'PATCH',
          body,
        }
      },
      invalidatesTags: [
        WorkspacesAPITagType.CompetitiveMentionAnalysis,
        WorkspacesAPITagType.CompetitiveMentionAnalysisList,
      ],
    }),
    deleteCompetitiveMentionAnalysis: build.mutation<
      void,
      Omit<BasicCompetitiveAnalysisRequest, 'country_code'>
    >({
      query: ({ workspaceId, id }) => {
        return {
          url: `/workspaces/${workspaceId}/competitive-mention-analyses/${id}`,
          method: 'DELETE',
        }
      },
      invalidatesTags: [WorkspacesAPITagType.CompetitiveMentionAnalysisList],
    }),
    fetchCompetitiveMentionKolPropertyChartData: build.query<
      FetchCompetitiveMentionKolPropertyChartDataResponse,
      FetchCompetitiveMentionKolPropertyChartDataRequest
    >({
      queryFn: async (
        { workspaceId, id, ...params },
        _queryApi,
        _extraOptions,
        baseQuery,
      ) => {
        if (id === DEMO_COMPETITIVE_ANALYSIS_ID) {
          return { data: demoCompetitiveMentionKolPropertyChartData }
        }

        return (await baseQuery({
          url: `/workspaces/${workspaceId}/competitive-mention-analyses/${id}/analyze/mention-kol-property-charts`,
          params: { ...params, ...defaultStringifyRequest },
        })) as { data: FetchCompetitiveMentionKolPropertyChartDataResponse }
      },
      extraOptions: {
        dataSchema: fetchCompetitiveMentionKolPropertyChartDataResponseSchema,
      },
      providesTags: [
        WorkspacesAPITagType.CompetitiveMentionKolPropertyChartData,
      ],
    }),
    fetchCompetitiveMentionSimilarKolList: build.query<
      FetchCompetitiveMentionSimilarKolListResponse,
      FetchCompetitiveMentionSimilarKolListRequest
    >({
      queryFn: async (
        { workspaceId, id, ...params },
        _queryApi,
        _extraOptions,
        baseQuery,
      ) => {
        if (id === DEMO_COMPETITIVE_ANALYSIS_ID) {
          return { data: demoCompetitiveMentionSimilarKolList }
        }

        return (await baseQuery({
          url: `/workspaces/${workspaceId}/competitive-mention-analyses/${id}/analyze/competitive-mention-similar-kols`,
          params: { ...params, ...defaultStringifyRequest },
        })) as { data: FetchCompetitiveMentionSimilarKolListResponse }
      },
      extraOptions: {
        dataSchema: fetchCompetitiveMentionSimilarKolListResponseSchema,
      },
    }),
    fetchCompetitiveMentionPostKolList: build.query<
      FetchCompetitiveMentionPostKolListResponse,
      FetchCompetitiveMentionPostKolListRequest
    >({
      queryFn: async (
        { workspaceId, id, ...params },
        _queryApi,
        _extraOptions,
        baseQuery,
      ) => {
        if (id === DEMO_COMPETITIVE_ANALYSIS_ID) {
          return { data: demoCompetitiveMentionPostKolList }
        }

        return (await baseQuery({
          url: `/workspaces/${workspaceId}/competitive-mention-analyses/${id}/analyze/competitive-mention-kols`,
          params: { ...params, ...defaultStringifyRequest },
        })) as { data: FetchCompetitiveMentionPostKolListResponse }
      },
      extraOptions: {
        dataSchema: fetchCompetitiveMentionPostKolListResponseSchema,
      },
    }),
    fetchCompetitiveMentionEffectivenessTrendChartData: build.query<
      FetchCompetitiveMentionEffectivenessTrendChartDataResponse,
      FetchCompetitiveMentionEffectivenessTrendChartDataRequest
    >({
      queryFn: async (
        { workspaceId, id, ...params },
        _queryApi,
        _extraOptions,
        baseQuery,
      ) => {
        if (id === DEMO_COMPETITIVE_ANALYSIS_ID) {
          return { data: demoCompetitiveMentionEffectivenessTrendChartData }
        }

        return (await baseQuery({
          url: `/workspaces/${workspaceId}/competitive-mention-analyses/${id}/analyze/effectiveness-trend`,
          params: { ...params, ...defaultStringifyRequest },
        })) as {
          data: FetchCompetitiveMentionEffectivenessTrendChartDataResponse
        }
      },
      extraOptions: {
        dataSchema:
          fetchCompetitiveMentionEffectivenessTrendChartDataResponseSchema,
      },
    }),
    fetchCompetitiveMentionEffectivenessTrendChartDetail: build.query<
      CompetitiveMentionEffectivenessTrendChartDetail,
      FetchCompetitiveMentionEffectivenessTrendChartDetailRequest
    >({
      queryFn: async (
        { workspaceId, id, ...params },
        _queryApi,
        _extraOptions,
        baseQuery,
      ) => {
        if (id === DEMO_COMPETITIVE_ANALYSIS_ID) {
          const demoCompetitiveMentionedEfficientTrendDetail =
            getDemoCompetitiveMentionedEfficientTrendDetail(
              params.uuid,
              params.time,
            )

          return { data: demoCompetitiveMentionedEfficientTrendDetail }
        }

        return (await baseQuery({
          url: `/workspaces/${workspaceId}/competitive-mention-analyses/${id}/analyze/effectiveness-trend/detail`,
          params,
        })) as {
          data: CompetitiveMentionEffectivenessTrendChartDetail
        }
      },
      extraOptions: {
        dataSchema: competitiveMentionEffectivenessTrendChartDetailSchema,
      },
    }),
    fetchCompetitiveMentionStaticHashtags: build.query<
      CompetitiveMentionStaticHashTagListResponse,
      FetchCompetitiveStaticHashTagListRequest
    >({
      queryFn: async (
        { workspaceId, id, ...params },
        _queryApi,
        _extraOptions,
        baseQuery,
      ) => {
        if (id === DEMO_COMPETITIVE_ANALYSIS_ID) {
          return {
            data: demoStaticHashtags,
          }
        }

        return (await baseQuery({
          url: `/workspaces/${workspaceId}/competitive-mention-analyses/${id}/analyze/hashtags`,
          params: { ...removeNil(params), ...defaultStringifyRequest },
        })) as {
          data: CompetitiveMentionStaticHashTagListResponse
        }
      },
      extraOptions: {
        dataSchema: competitiveStaticHashTagsSchema,
      },
      providesTags: [
        WorkspacesAPITagType.CompetitiveMentionAnalysesHashTagList,
      ],
    }),
    reportCompetitiveKols: build.mutation<
      ReportKolsResponse,
      ReportCompetitiveKolsRequest
    >({
      queryFn: async (payload, _queryApi, _extraOptions, baseQuery) => {
        const { workspaceId, id, ...body } = payload
        const result = await baseQuery({
          url: `/workspaces/${workspaceId}/competitive-mention-analyses/${id}/kols`,
          method: 'POST',
          body,
        })

        // kol 已存在
        if (result.error?.status === 409) {
          return {
            data: { type: 'exist', data: result.error.data as ReportKol },
          }
        }

        // kol 被隱藏
        if (result.error?.status === 410) {
          return {
            data: { type: 'hidden' },
          }
        }

        // 預期外的錯誤
        if (result.error) {
          return {
            data: {
              type: 'error',
            },
          }
        }

        return {
          data: { type: 'success' },
        }
      },
      invalidatesTags: [WorkspacesAPITagType.CompetitiveMentionAnalysisKolList],
    }),
  }),
  overrideExisting: false,
})

export const {
  useFetchCompetitiveKeywordTopTenPostListQuery,
  useFetchCompetitiveMentionTopTenPostListQuery,
  useFetchCompetitiveKeywordHashtagListQuery,
  useFetchCompetitiveKeywordPostKolListQuery,
  useLazyExportCompetitiveKeywordPostKolListQuery,
  useLazyExportCompetitiveMentionPostKolListQuery,
  useFetchCompetitiveKeywordKolCustomizedTagsNameChartDataQuery,
  useFetchCompetitiveMentionKolFollowerChartDataQuery,
  useFetchCompetitiveKeywordAnalysisListQuery,
  useFetchCompetitiveKeywordAnalysisKeywordListQuery,
  useCreateCompetitiveKeywordAnalysisMutation,
  useDeleteCompetitiveKeywordAnalysisMutation,
  useModifyCompetitiveKeywordAnalysisMutation,
  useUpdateCompetitiveKeywordAnalysisKeywordListMutation,
  useFetchCompetitiveKeywordAnalysisQuery,
  useFetchCompetitiveKeywordStatisticListQuery,
  useFetchCompetitiveKeywordEffectivenessTrendChartDataQuery,
  useFetchCompetitiveKeywordKolFollowerChartDataQuery,
  useFetchCompetitiveKeywordEffectivenessTrendChartDetailQuery,
  useFetchCompetitiveKeywordAnalysesCreatorListQuery,
  useFetchCompetitiveKeywordSimilarKolListQuery,
  useFetchCompetitiveBrandAnalysisCrawlerStatusQuery,
  useTriggerCompetitiveBrandAnalysisCrawlerMutation,
  useFetchCompetitiveBrandAnalysisEffectivenessTrendQuery,
  useFetchCompetitiveBrandAnalysisKolListChartsQuery,
  useFetchCompetitiveBrandAnalysisKolListMetricChartsQuery,
  useFetchCompetitiveBrandAnalysisStaticHashtagsQuery,
  useFetchCompetitiveMentionAnalysisQuery,
  useFetchCompetitiveMentionAnalysisListQuery,
  useFetchCompetitiveMentionAnalysisKolListQuery,
  useUpdateCompetitiveMentionAnalysisKolListMutation,
  useCreateCompetitiveMentionAnalysisMutation,
  useFetchCompetitiveMentionAnalysesCreatorListQuery,
  useModifyCompetitiveMentionAnalysisMutation,
  useDeleteCompetitiveMentionAnalysisMutation,
  useFetchCompetitiveKolStatisticListQuery,
  useFetchCompetitiveKolStatisticListChartsQuery,
  useFetchCompetitiveMentionKolPropertyChartDataQuery,
  useFetchCompetitiveMentionAnalysisKolAnalyzeListQuery,
  useFetchCompetitiveMentionSimilarKolListQuery,
  useFetchCompetitiveMentionPostKolListQuery,
  useFetchCompetitiveMentionEffectivenessTrendChartDataQuery,
  useFetchCompetitiveMentionEffectivenessTrendChartDetailQuery,
  useFetchCompetitiveMentionStaticHashtagsQuery,
  useReportCompetitiveKolsMutation,
} = competitiveAnalysesApi
