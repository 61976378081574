import freeAvatar1Src from '@/assets/images/kol/free-avatar1.png'
import freeAvatar2Src from '@/assets/images/kol/free-avatar2.png'
import freeAvatar3Src from '@/assets/images/kol/free-avatar3.png'
import freeAvatar4Src from '@/assets/images/kol/free-avatar4.png'
import freeAvatar5Src from '@/assets/images/kol/free-avatar5.png'
import freeAvatar6Src from '@/assets/images/kol/free-avatar6.png'
import freeAvatar7Src from '@/assets/images/kol/free-avatar7.png'
import freeAvatar8Src from '@/assets/images/kol/free-avatar8.png'
import freeAvatar9Src from '@/assets/images/kol/free-avatar9.png'
import freeAvatar10Src from '@/assets/images/kol/free-avatar10.png'
import freeBrandAvatar6Src from '@/assets/images/kol/free-brand-avatar-6.png'
import freeBrandAvatar7Src from '@/assets/images/kol/free-brand-avatar-7.png'
import freeBrandAvatar8Src from '@/assets/images/kol/free-brand-avatar-8.png'
import { KolSchemaModel } from '@/types/schema/kol-schema'
import { Potential } from '@/utils/detail/potential'

// KolSchemaModel 中沒有假資料需求的屬性就宣告在這邊
const unimportantAttrs = {
  gender: 'Female',
  traceId: 0,
  property: [],
  customizedTagsNames: [],
  specialty: '',
  description: '',
  customTag: null,
  verified: false,
  isRadarStar: false,
  visibility: false,
  links: [],
  urls: [],
  kpi: [],
  postCount: 0,
  activeLink: '',
  maxInteractiveRate3m: 0,
  maxFansUpRate3m: 0,
  maxAvgPvRate3m: 0,
  agentInfo: null,
  crawledDescription: null,
  cowork: undefined,
  statistics: undefined,
  fans: undefined,
  editable: undefined,
  portfolioStatus: undefined,
  potential: Potential.Any,
  score: undefined,
  countryCode: null,
  recommend: '' as const,
  resourcesInvolved: undefined,
  isCommunityKol: false,
  isCollectionKol: false,
  snapshotAt: null,
  snapshotUpdatedAt: null,
  quotations: null,
  isUnlocked: false,
  matchedPosts: [],
  latestPosts: [],
  withNewDataToUnlock: null,
  languages: null,
  similarKolPlatform: null,
  isAddedToDirectory: false,
}

const MOCK_KOL_INFO_PREFIX = '@@@@_mock'
export const MOCK_CUSTOMIZED_TAG_NAME_PREFIX = '@@@@_mock_customized_tag_'

const mockKolInfo1: KolSchemaModel = {
  id: `${MOCK_KOL_INFO_PREFIX}-1`,
  name: 'I’m Cindy',
  subName: ['I’m Cindy'],
  thumbnail: freeAvatar1Src,
  follower: 1300,
  ...unimportantAttrs,
  customizedTagsNames: [
    `${MOCK_CUSTOMIZED_TAG_NAME_PREFIX}Beauty and Fashion`,
    `${MOCK_CUSTOMIZED_TAG_NAME_PREFIX}Life Style`,
    `${MOCK_CUSTOMIZED_TAG_NAME_PREFIX}Travel`,
    `${MOCK_CUSTOMIZED_TAG_NAME_PREFIX}Sports`,
  ],
}
const mockKolInfo2: KolSchemaModel = {
  id: `${MOCK_KOL_INFO_PREFIX}-2`,
  name: 'Johnny',
  subName: ['Johnny'],
  thumbnail: freeAvatar2Src,
  follower: 5300,
  ...unimportantAttrs,
  customizedTagsNames: [
    'Gaming',
    'Technology',
    'Food',
    'Medical and Health',
    'Education',
  ],
}
const mockKolInfo3: KolSchemaModel = {
  id: `${MOCK_KOL_INFO_PREFIX}-3`,
  name: '強強生活',
  subName: ['強強生活'],
  thumbnail: freeAvatar3Src,
  follower: 2800,
  ...unimportantAttrs,
}
const mockKolInfo4: KolSchemaModel = {
  id: `${MOCK_KOL_INFO_PREFIX}-4`,
  name: 'I’m Cindy',
  subName: ['I’m Cindy'],
  thumbnail: freeAvatar4Src,
  follower: 1300,
  ...unimportantAttrs,
}

const mockKolInfo5: KolSchemaModel = {
  id: `${MOCK_KOL_INFO_PREFIX}-5`,
  name: 'Mindy',
  subName: ['Mindy'],
  thumbnail: freeAvatar5Src,
  follower: 7600,
  ...unimportantAttrs,
}

const mockKolInfo6: KolSchemaModel = {
  id: `${MOCK_KOL_INFO_PREFIX}-6`,
  name: '美食探險家',
  subName: ['美食探險家'],
  thumbnail: freeAvatar6Src,
  follower: 7600,
  ...unimportantAttrs,
}

const mockKolInfo7: KolSchemaModel = {
  id: `${MOCK_KOL_INFO_PREFIX}-7`,
  name: '雨天窩在窗邊',
  subName: ['雨天窩在窗邊'],
  thumbnail: freeAvatar7Src,
  follower: 7600,
  ...unimportantAttrs,
}

const mockKolInfo8: KolSchemaModel = {
  id: `${MOCK_KOL_INFO_PREFIX}-8`,
  name: '甜點地圖',
  subName: ['甜點地圖'],
  thumbnail: freeAvatar8Src,
  follower: 7600,
  ...unimportantAttrs,
}

const mockKolInfo9: KolSchemaModel = {
  id: `${MOCK_KOL_INFO_PREFIX}-9`,
  name: '味蕾旅行家',
  subName: ['味蕾旅行家'],
  thumbnail: freeAvatar9Src,
  follower: 7600,
  ...unimportantAttrs,
}

const mockKolInfo10: KolSchemaModel = {
  id: `${MOCK_KOL_INFO_PREFIX}-10`,
  name: '甜甜ㄉ每一天',
  subName: ['甜甜ㄉ每一天'],
  thumbnail: freeAvatar10Src,
  follower: 7600,
  ...unimportantAttrs,
}

const mockBrandInfo6: KolSchemaModel = {
  id: `${MOCK_KOL_INFO_PREFIX}-brand-6`,
  name: '品牌 A',
  subName: ['品牌 A'],
  thumbnail: freeBrandAvatar6Src,
  follower: 7600,
  ...unimportantAttrs,
}

const mockBrandInfo7: KolSchemaModel = {
  id: `${MOCK_KOL_INFO_PREFIX}-brand-7`,
  name: '品牌 B',
  subName: ['品牌 B'],
  thumbnail: freeBrandAvatar7Src,
  follower: 7600,
  ...unimportantAttrs,
}

const mockBrandInfo8: KolSchemaModel = {
  id: `${MOCK_KOL_INFO_PREFIX}-brand-8`,
  name: '品牌 C',
  subName: ['品牌 C'],
  thumbnail: freeBrandAvatar8Src,
  follower: 7600,
  ...unimportantAttrs,
}

const mockKolInfoList = [
  mockKolInfo1,
  mockKolInfo2,
  mockKolInfo3,
  mockKolInfo4,
  mockKolInfo5,
  mockKolInfo6,
  mockKolInfo7,
  mockKolInfo8,
  mockKolInfo9,
  mockKolInfo10,
  mockBrandInfo6,
  mockBrandInfo7,
  mockBrandInfo8,
]

export {
  MOCK_KOL_INFO_PREFIX,
  mockBrandInfo6,
  mockBrandInfo7,
  mockBrandInfo8,
  mockKolInfo1,
  mockKolInfo10,
  mockKolInfo2,
  mockKolInfo3,
  mockKolInfo4,
  mockKolInfo5,
  mockKolInfo6,
  mockKolInfo7,
  mockKolInfo8,
  mockKolInfo9,
  mockKolInfoList,
}
